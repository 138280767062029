<template>
  <div>
    <h2 class="admin-title">특정일 예약거절</h2>
    <ParkingDropdown v-model="selectedParkingLot" :items="parkingLots" :defaultText="parkingLots.name" @change="selectParkingLot"/>
    <div class="content-container">
    <ManageTable 
      v-if="selectedParkingLot"
      :APIDatas="APIDatas" 
      :columns="columns" 
      class="table-container"
      @delete-item="deleteItem"
      :showDeleteColumn="showDeleteColumn"
      style="width: 70%"/>
      <FormComponent
        :fields="formFields"
        submitButtonText="등록"
        @submit="handleFormSubmit"
        class="form-container"
        style="width: 30%;"
      />
    </div>
  </div>
</template>

  
<script>
import ManageTable from '@/components/ManageTable.vue';
import ParkingDropdown from '@/components/ParkingDropdown.vue';
import FormComponent from '@/components/FormComponent.vue';
import { getParkingList, getParkingBan, postParkingBan, deleteParkingBan } from '@/api/api';

export default {
  components: {
    ManageTable,
    ParkingDropdown,
    FormComponent
  },
  data() {
    return {
      columns: ["시작일", "종료일"],
      parkingLots: [],
      selectedParkingLot: null,
      APIDatas: [],
      formFields: [
      { name: 'startdate', label: '시작일', type: 'date', placeholder: '' },
      { name: 'starttime', label: '시작시간', type: 'time', placeholder: '' },
      { name: 'enddate', label: '종료일', type: 'date', placeholder: '' },
      { name: 'endtime', label: '종료시간', type: 'time', placeholder: '' }
      ]
    };
  },
  created() {
    this.fetchParkingList();
  },
  watch: {
    selectedParkingLot(newVal) {
      if (newVal) {
        this.fetchParkingBanData(newVal.id); // 주차장이 선택되면 예약 데이터 가져오기
      }
    }
  },
  methods: {
    async fetchParkingList() {
      try {
        const parkingLots = await getParkingList();
        this.parkingLots = parkingLots;
        if (this.parkingLots.length > 0) {
          this.selectedParkingLot = this.parkingLots[0]; // 첫 번째 주차장을 디폴트로 선택
          this.fetchParkingBanData(this.selectedParkingLot.id); // 첫 번째 주차장의 예약 데이터 가져오기
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchParkingBanData(parkingLotId) {
      if (parkingLotId === undefined) {
        console.warn('Invalid parking lot ID');
        return;
      }
      try {
        const APIDatas = await getParkingBan(parkingLotId);
        this.APIDatas = APIDatas.map(data => data.displayData);
      } catch (error) {
        console.error('Error fetching parking lots:', error);
      }
    },
    selectParkingLot(parkingLot) {
      this.selectedParkingLot = parkingLot;
      this.fetchParkingBanData(); // 주차장 선택 시 예약 데이터 가져오기
    },
    async handleFormSubmit(formData) {
      if (formData instanceof SubmitEvent) {
        console.warn('Invalid form data:', formData);
        return;
      }     
      
      try {        
        const dataToSend = {
          startDateTime: `${formData.startdate}T${formData.starttime}`,
          endDateTime: `${formData.enddate}T${formData.endtime}`
        };

        await postParkingBan(this.selectedParkingLot.id, dataToSend);
        // // 업데이트된 데이터를 다시 가져옵니다.
        this.fetchParkingBanData(this.selectedParkingLot.id);
      } catch (error) {
        console.error('Error submitting form:', error);
      }  
    },

    async deleteItem(index) {
      const banscheduleID = this.APIDatas[index].No;
      try {
        await deleteParkingBan(this.selectedParkingLot.id, banscheduleID);
        this.APIDatas.splice(index, 1);
      } catch (error) {
        console.error('Error deleting reservation:', error);
        // 추가적인 에러 처리 로직이 필요하면 여기서 작성하세요.
      }
    },

  }
};
</script>
