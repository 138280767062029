<template>
  <div>
    <div class="inquiry-wrapper">
      <table class="custom-table-view">
        <colgroup>
          <col style="width: 8%;" />
          <col style="width: 92%;" />
        </colgroup>
        <thead>
          <tr>
            <th>분류</th>
            <th>{{ localInquiryData.분류 }}</th>
          </tr>
        </thead>
        <tr>
          <td>작성자</td>
          <td>{{ localInquiryData.작성자 }}</td>
        </tr>
        <tr>
          <td>작성날짜</td>
          <td>{{ localInquiryData.작성날짜 }}</td>
        </tr>
        <tr>
          <td class="large-cell">문의내역</td>
          <td class="large-cell">{{ localInquiryData.문의내역 }}</td>
        </tr>
        <tr class="answer-row">
          <td class="large-cell">답변</td>
          <td class="answer-cell">
            <textarea 
              v-model="answer" 
              class="answer-textarea"
              :disabled="!isAnswer"
            ></textarea>
          </td>
        </tr>
      </table>
      <div class="btn-wrapper">
        <button class="close-btn" @click="closeModal">목록으로</button>
        <div class="right-button">
          <button v-if=isAnswer class="cancel-btn" @click="clickCancel">취소</button>
          <button v-if=!localInquiryData.답변내용 class="register-btn" @click="fetchAnswer">답변등록</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiClient } from '@/api/api';
export default {
  props: {
    inquiryData: {
      type: Object,
      required: true
    }
  },
  data() {
      return {
        localInquiryData: { ...this.inquiryData }, // inquiryData의 복사본 생성
        answer: null, // 답변 텍스트 초기화
        isAnswer: false,
      };
  },
  created() {
    if (this.localInquiryData.답변내용) {
      this.answer = this.localInquiryData.답변내용;
    }
  },
  methods: {
    fetchAnswer() {
      if (this.isAnswer) {
      const confirmed = confirm(`문의내역에 답변을 등록하시겠습니까?`);
        if (confirmed) {
          this.postAnswer()
        } 
      } else {
        this.isAnswer = true
      }
    },
    closeModal() {
      this.isAnswer = false
      this.$emit('close');
    },
    clickCancel() {
      this.answer = null
      this.isAnswer=false
    },
    async postAnswer() {
        try {
          const response = apiClient.post(`/inquiries/${this.inquiryData.순번}/answer`, 
            {'answer': this.answer}
          );
          if (response) {
            alert('문의내역에 답변이 등록되었습니다.');
          } else {
            alert('서버 오류로 답변 등록에 실패하였습니다.\n잠시후 다시 시도해주세요.');
          }
        } catch (error) {
            console.error('답변 등록 실패:', error);
        } finally {
          this.localInquiryData.답변내용 = this.answer;
          this.isAnswer = false
        }
    }

  }
};
</script>

<style scoped>

.inquiry-wrapper {
  margin-top: 1rem;
}
.btn-wrapper {
  margin: .5rem 0;
  display: flex;
  justify-content: space-between;
}
.custom-table-view td, .custom-table-view th {
  border-left: 2px solid var(--admin-main-color); /* 왼쪽에 세로줄 추가 */
  padding: 8px; /* 패딩 추가 */
  text-align: start;
}

.large-cell {
  vertical-align: top;
  height: 25vh;
}

.answer-cell {
  vertical-align: top;
  height: 25vh;
  padding: 0;
}

.close-btn {
  background: white; 
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid var(--admin-menu1-color);
  padding: .5rem;
  cursor: pointer;
  color: var(--admin-menu1-color);
}

.register-btn {
  background: var(--admin-main-color); 
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid var(--admin-main-color);
  padding: .5rem;
  cursor: pointer;
  color: var(--admin-menu1-color);
}

.cancel-btn {
  background: white; 
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid var(--admin-menu1-color);
  padding: .5rem;
  margin: 0 .5rem 0 0;
  cursor: pointer;
  color: var(--admin-menu1-color);
}

.answer-textarea {
  width: 99%;
  height: 25vh;
  font-size: 1rem;
  border-radius: .5rem;
  border: none;
  resize: none;
  padding: 0.5rem;
  background-color: rgb(239, 239, 239);
}

.answer-textarea:focus {
  border: none; /* 포커스 시에도 border 제거 */
  outline: none; /* 포커스 시에도 아웃라인 제거 */
}

.answer-textarea:disabled {
  background-color: white;
}

</style>
