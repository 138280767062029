<template>
  <div class="dropdown" ref="dropdown">
    <button @click="toggleDropdown" class="dropdown-button">
      <img src='@/img/ic_car.png' alt="Icon" class="dropdown-icon"/> {{ selectedText }}
    </button>
    <div v-if="showDropdown" class="dropdown-content">
      <span v-for="item in items" :key="item.id" @click="selectItem(item)">
        {{ item.name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    defaultText: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showDropdown: false,
      selectedText: this.defaultText,
    };
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.selectedText = newValue.name;
      } else {
        this.selectedText = this.defaultText;
      }
    },
  },
  created() {
    if (this.value) {
      this.selectedText = this.value.name;
    } else {
      this.selectedText = this.defaultText;
    }
    
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectItem(item) {
      this.$emit('input', item); // v-model input 이벤트 트리거
      this.$emit('change', item); // change 이벤트 트리거
      this.selectedText = item.name;
      this.showDropdown = false;
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.showDropdown = false;
      }
    }
  }
};
</script>


<style>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: transparent;
  color: var(--admin-menu1-color);
  margin: 20px 0px 0px 0px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-icon {
  width: 20px;
  margin-right: 10px;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-content span:hover {
  border-radius: 10px;
  background-color: var(--admin-hover-color);
}

.dropdown-content img {
  margin-right: 5px;
}
</style>
