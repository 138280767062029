<template>
  <div class="form-container">
    <form @submit.prevent="handleSubmit">
      <div v-for="(field, index) in fields" :key="index" :class="['admin-form-group', { 'no-label': !field.label }]">
        <label :for="field.name">{{ field.label }}</label>
        <input
          v-if="field.type === 'text'"
          type="text"
          :id="field.name"
          :name="field.name"
          v-model="formData[field.name]"
          :placeholder="field.placeholder"
        />

        <select v-if="field.type === 'select'" v-model="formData[field.name]" class="form-select">
          <option disabled value="">{{field.placeholder}}</option>
          <option v-for="option in field.selectList" 
          :key="option.id || option" 
          :value="option.id || option" 
          class="form-select-option">{{ option.name || option }}</option>
        </select>

        <input
          v-if="field.type === 'date'"
          type="date"
          :id="field.name"
          :name="field.name"
          v-model="formData[field.name]"
        />
        <div v-if="field.type === 'time'" class="time-picker">
          <select v-model="formData[field.name].hours" class="form-select">
            <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}</option>
          </select>
          :
          <select v-model="formData[field.name].minutes" class="form-select">
            <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}</option>
          </select>
        </div>

        <div v-if="field.type === 'address'">
          <select v-model="selectedSido" @change="fetchSigunguOptions" class="address-select">
            <option disabled value="">시/도</option>
            <option v-for="sido in sidoOptions" :key="sido.code" :value="sido.code">{{ sido.name }}</option>
          </select>
          <select v-model="selectedSigungu" @change="fetchEupmyundongOptions" :disabled="!selectedSido" class="address-select">
            <option disabled value="">시/군/구</option>
            <option v-for="sigungu in sigunguOptions" :key="sigungu.code" :value="sigungu.code">{{ sigungu.name }}</option>
          </select>
          <select v-model="selectedEupmyundong" :disabled="!selectedSigungu" class="address-select">
            <option disabled value="">읍/면/동</option>
            <option v-for="eupmyundong in eupmyundongOptions" :key="eupmyundong.code" :value="eupmyundong.code">{{ eupmyundong.name }}</option>
          </select>
          <input v-model="detailedAddress" type="text" placeholder="상세주소 입력" />
        </div>
      </div>
      <div class="button-group">
      <button type="submit" class="submit-button">{{ submitButtonText }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { fetchToken, getSido, getSigungu, getEupmyundong } from '@/api/api';

export default {
  props: {
    fields: {
      type: Array,
      required: true
    },
    submitButtonText: {
      type: String,
      default: 'Submit'
    }
  },

  data() {
    return {
      formData: this.initializeFormData(),
      hours: Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0')),
      minutes: Array.from({ length: 6 }, (_, i) => (i*10).toString().padStart(2, '0')),
      
      sidoOptions: [],
      sigunguOptions: [],
      eupmyundongOptions: [],
      selectedSido: '',
      selectedSigungu: '',
      selectedEupmyundong: '',
      selectedSidoName: '',
      selectedSigunguName: '',
      selectedEupmyundongName: '',
      detailedAddress: ''
    };
  },

  created() {
    if (this.fields.some(field => field.type === 'address')) {
      this.fetchSidoOptions();
    }
  },

  methods: {
    initializeFormData() {
      const initialFormData = {};
      this.fields.forEach(field => {
        initialFormData[field.name] = field.type === 'time' ? { hours: '00', minutes: '00' } : '';
      });
      return initialFormData;
    },

    async fetchSidoOptions() {
      await fetchToken();
      this.sidoOptions = await getSido();
    },
    async fetchSigunguOptions() {
      this.sigunguOptions = await getSigungu(this.selectedSido);
    },
    async fetchEupmyundongOptions() {
      this.eupmyundongOptions = await getEupmyundong(this.selectedSigungu);
    },

    validateFormData() {
      const missingFields = [];
      this.fields.forEach(field => {
        if (field.type === 'time') {
          if (!this.formData[field.name].hours || !this.formData[field.name].minutes) {
            missingFields.push(field.label || field.name);
          }
        } else if (field.type === 'address') {
            if (!this.selectedSido || !this.selectedSigungu || !this.selectedEupmyundong || !this.detailedAddress) {
              missingFields.push(field.label || field.name);
            }
        } else if (!this.formData[field.name]) {
          missingFields.push(field.label || field.name);
        }
      });
      return missingFields;
    },

    handleSubmit() {      
      const missingFields = this.validateFormData();

      if (missingFields.length > 0) {
        alert(`다음 필드를 입력해주세요: ${missingFields.join(', ')}`);
        return;
      }
      const hasAddressField = this.fields.some(field => field.type === 'address');
      if (hasAddressField) {
        this.formData.sidoname = this.sidoOptions.find(sido => sido.code === this.selectedSido).name;
        this.formData.sigunguname = this.sigunguOptions.find(sigungu => sigungu.code === this.selectedSigungu).name;
        this.formData.eupmyundongname = this.eupmyundongOptions.find(eupmyundong => eupmyundong.code === this.selectedEupmyundong).name;
        this.formData.detailname = this.detailedAddress;
      }
      
      const hasTimeField = this.fields.some(field => field.type === 'time');
      if (hasTimeField) {
        Object.keys(this.formData).forEach(key => {
          if (typeof this.formData[key] === 'object' && this.formData[key].hours !== undefined) {
            this.formData[`${key}_string`] = `${this.formData[key].hours}${this.formData[key].minutes}`;
            this.formData[key] = `${this.formData[key].hours}:${this.formData[key].minutes}:00`;
          }
        });
      }
      this.$emit('submit', this.formData);
      this.formData = this.initializeFormData();
    },
  }
};
</script>

<style>
.address-select {
  margin-bottom: 10px; 
}

.admin-form-group {
  margin-top: 20px;
}

.admin-form-group.no-label {
margin-top: 10px; /* 레이블이 없는 경우 더 작은 마진 */
}

form label {
  display: block;
  margin-bottom: 5px;
}

form input,
form select {
  width: 100%;
  padding: 5px 8px ;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  box-sizing: border-box;
}

form input:focus,
form select:focus {
  outline: none;
  border: 1px solid whitesmoke;
}

.button-group {
text-align: right; 
}

.time-picker {
  display: flex;
  align-items: center;
}

.form-select {
  width: auto;
  padding: 5px 8px;
}

</style>
