<template>
  <div class="admin-page">
    <h2 class="admin-title">입출차 리스트</h2>
    <div v-if="loading"></div>
    <div v-else class="admin-page">
      <div v-if="parkingLots.length > 0"> 
        <ParkingDropdown :items="parkingLots" :defaultText="selectedParkingLot.name" @change="selectParkingLot"/>
        <div class="filters-container">
          <div class="left-filters">
            <FilterButton v-model="activeFilter" :filters="filters" />
            <input type="date" v-model="selectedDate" class="date-picker" />
          </div>
          <SearchBar
            v-model="searchQuery"
            :field="searchField"
            :fields="searchFields"
            @search="handleSearch"
            @field-change="handleFieldChange"
          />
        </div>
        <div class="content-container no-margin-padding">
          <div ref="tableContainer" :class="['table-container', { 'table-selected': selectedReservation }]">
            <ReservationTable
              v-if="selectedParkingLot"
              :reservations="filteredReservations"
              :columns="columns"
              @row-click="selectReservation"
              :selectedReservation="selectedReservation"
            />
          </div>
        </div>
      </div>
      <div v-else class="empty-container">
        <EmptyParkinglot/>
      </div>
    </div>
    <ImageModal
      v-if="showModal"
      :reservId="photoId"
      :isVisible="showModal"
      @close="closeModal"
    />
    <ReservationDetailSidebar
      v-if="showVal"
      :parkingList="selectedReservation"
      :parkingLotName="selectedParkingLot.name" 
      @close="closeDetail"
      @photo="viewPhoto"
    />
  </div>      
</template>
  
<script>
  import ParkingDropdown from '@/components/ParkingDropdown.vue';
  import FilterButton from '@/components/FilterButton.vue';
  import SearchBar from '@/components/SearchBar.vue';
  import ReservationTable from '@/components/ReservationTable.vue';
  import EmptyParkinglot from '@/components/EmptyParkinglot.vue'
  import { getParkingList, getEntryExit } from '@/api/api';
  import ImageModal from '@/components/ImageModal.vue';
  import { mapActions, mapGetters } from 'vuex';
  import ReservationDetailSidebar from '@/components/ReservationDetailSidebar.vue';

  export default {
    components: {
      ReservationTable,
      ReservationDetailSidebar,
      ParkingDropdown,
      FilterButton,
      SearchBar,
      EmptyParkinglot,
      ImageModal
    },
    data() {
      return {
        loading: true,
        columns: ["회원구분", "차량 정보", "연락처",  "입차 시간", "출차 시간", "입출차 상태"],      
        parkingLots: [],
        selectedReservation: null,
        reservations: [],
        activeFilter: 'ALL',
        searchQuery: '',
        searchField: '',
        selectedDate: this.getCurrentDate(),
        filters: [
          { label: '전체', value: 'ALL' },
          { label: '입차', value: '입차' },
          { label: '출차', value: '출차' }
        ],
        searchFields: [
          { label: '차종', value: 'vehicleType' },
          { label: '차량번호', value: 'vehicleNumber' },
          { label: '전화번호(뒤4자리)', value: 'customerPhone4' }
        ],
        showVal: false,
        showModal: false,
        photoId: null,
      };
    },
    created() {
      this.fetchParkingList().then(() => {
        // 주차장이 선택된 후에 예약 목록을 가져옵니다.
        if (this.selectedParkingLot) {
          this.fetchReservations(this.selectedParkingLot.id);
        }
      });
    },
    computed: {
      ...mapGetters('parkingLot', ['selectedParkingLot']),
      filteredReservations() {
        let reservations = this.reservations.filter(
          (reservation) => reservation.displayData['예약상태'] != "예약취소");

        const date = this.selectedDate;
        if (this.activeFilter === '입차') {
          reservations = reservations.filter( 
            (reservation) => reservation.displayData['입차일'].startsWith(date));
        } else if (this.activeFilter === '출차') {
          reservations = reservations.filter( 
            (reservation) => reservation.displayData['출차일'].startsWith(date));
        } else {
          reservations = reservations.filter(
            (reservation) => reservation.displayData['입차일'].startsWith(date) || reservation.displayData['출차일'].startsWith(date));
        }
            
        if (this.searchQuery && this.searchField) {
          const queryLower = this.searchQuery.toLowerCase();
          reservations = reservations.filter((reservation) =>
            String(reservation[this.searchField])
              .toLowerCase()
              .includes(queryLower)
          );
        }

        return reservations.map((reservation) => reservation.displayData);
      },
    },
    watch: {
      selectedParkingLot(newVal) {
        if (newVal) {
          this.activeFilter = 'ALL';  // 주차장이 변경될 때 필터 리셋
          this.selectedReservation = null
          this.fetchReservations(newVal.id); // 주차장이 선택되면 예약 데이터 가져오기
        }
      },
      activeFilter() {
        this.selectedReservation = null;
        this.fetchReservations(this.selectedParkingLot.id)
      },
      selectedDate() {
        this.fetchReservations(this.selectedParkingLot.id)
      }
    },
    methods: {
      ...mapActions('parkingLot', ['setselectParkingLot']),
      async fetchParkingList() {
        try {
          const parkingLots = await getParkingList();
          this.parkingLots = parkingLots;
          if (!this.selectedParkingLot) {
            await this.setselectParkingLot(this.parkingLots[0]); 
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      },
      async fetchReservations(parkingLotId) {
        try {
          this.error = null; // 에러 상태 초기화
          const reservations = await getEntryExit(parkingLotId,this.selectedDate);
          this.reservations = reservations;
        } catch (error) {
          console.error(error);
        }
      },
      selectParkingLot(parkingLot) {
        this.setselectParkingLot(parkingLot);
      },
      getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      selectReservation(reservation) {
        this.showVal = true;
        this.selectedReservation = reservation;
      },
      clearReservation() {
        this.selectedReservation = null;
      },
      handleSearch({ query, field }) {
        this.searchQuery = query;
        this.searchField = field;
      },
      handleFieldChange(field) {
        this.searchField = field;
      },
      handleClickOutside(event) {
        const reservationDetail = this.$refs.reservationDetail;
        const tableContainer = this.$refs.tableContainer;
        if (reservationDetail && !reservationDetail.$el.contains(event.target) &&
        tableContainer && !tableContainer.contains(event.target)) {
          this.clearReservation();
        }
      },
      viewPhoto(reservId) {
        this.photoId = reservId;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false
      },
      closeDetail() {
        this.showVal = false
      }
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleClickOutside);
    }
  };
</script>
  
<style>
  .filters-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .left-filters {
    display: flex;
    align-items: center;
  }
  .date-picker {
    padding: 10px;
    border: 2px solid var(--admin-main-color);
    border-radius: 10px;
    margin-left: 20px;
  }

  .table-container {
    flex: 2; /* 테이블이 더 큰 너비를 차지하도록 설정 */
    transition: flex 0.3s; /* 부드러운 전환 효과 추가 */
  }

  .table-selected {
    flex: 1.4; /* 선택된 상태에서 테이블의 너비를 줄임 */
  }

  .details-container {
    display: none;
    transition: flex 0.3s, display 0.3s; /* 부드러운 전환 효과 추가 */
  }

  .details-visible {
    flex: 0.5;
    display: flex;
    align-items: center; /* 수직 가운데 정렬 */
    justify-content: center; /* 수평 가운데 정렬 */
  }
</style>