<template>
    <div class="pagination">
        <button @click="goToFirstPage" :disabled="currentPage === 1">
            <img src="@/img/icons/ic_firstpage.svg" alt="First Page" />
        </button>
        <button @click="prevPage" :disabled="currentPage === 1">
            <img src="@/img/icons/ic_prevpage.svg" alt="Previous Page" />
        </button>
      <button
        v-for="page in visiblePages"
        :key="page"
        @click="changePage(page)"
        :class="{ active: currentPage === page }"
      >
        {{ page }}
      </button>
      <button @click="nextPage" :disabled="currentPage === totalPages">
        <img src="@/img/icons/ic_nextpage.svg" alt="Next Page" />
      </button>
      <button @click="goToLastPage" :disabled="currentPage === totalPages">
        <img src="@/img/icons/ic_lastpage.svg" alt="Last Page" />
      </button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      currentPage: {
        type: Number,
        required: true
      },
      totalPages: {
        type: Number,
        required: true
      }
    },
    computed: {
      visiblePages() {
        const pages = [];
        const startPage = Math.max(1, this.currentPage - 2);
        const endPage = Math.min(this.totalPages, startPage + 4);
        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
        return pages;
      }
    },
    methods: {
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.$emit('page-changed', this.currentPage + 1);
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.$emit('page-changed', this.currentPage - 1);
        }
      },
      changePage(page) {
        this.$emit('page-changed', page);
      },
      goToFirstPage() {
        if (this.currentPage !== 1) {
          this.$emit('page-changed', 1);
        }
      },
      goToLastPage() {
        if (this.currentPage !== this.totalPages) {
          this.$emit('page-changed', this.totalPages);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    padding: 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .pagination button img {
    width: 16px; /* Adjust the size as needed */
    height: 16px; /* Adjust the size as needed */
  }

  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  
  .pagination button.active {
    background-color: var(--admin-main-color);
    border-radius: 5px;
  }
  
  .pagination button:hover:not(:disabled) {
    background-color: var(--admin-hover-color);
  }
  </style>
  