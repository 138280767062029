<template>
  <div>
    <h2 class="admin-title">수요응답버스 예약리스트</h2>
      <div class="filter-search-container">
        <ParkingDropdown :items="parkingLots" :defaultText="selectedParkingLot.name" @change="selectParkingLot"/>
        <SearchBar
          v-model="searchQuery"
          :field="searchField"
          :fields="searchFields"
          @search="handleSearch"
          @field-change="handleFieldChange"/>
      </div>
      <div class="filter-search-container">
      <FilterButton v-model="activeFilter" :filters="filters" />
      <DateRangePicker ref="dateRangePicker" @search="handlePeriodSearch" :periodOptions="periodOptions" />
    </div>
    <div class="content-container no-margin-padding">
      <div :class="['table-container', { 'table-selected': selectedReservation }]">
        <ReservationTable
          v-if="selectedParkingLot"
          :reservations="filteredReservations"
          :columns="columns"
          @row-click="selectReservation"
          :selectedReservation="selectedReservation"
        />
      </div>
      <div :class="['details-container', { 'details-visible': selectedReservation }]">
        <ReservationDetail 
        v-if="selectedReservation" 
        :reservation="selectedReservation" 
        :parkingLotName="selectedParkingLot.name" 
        @close="clearReservation" />
      </div>
    </div>
  </div>
</template>
  
<script>
  import ParkingDropdown from '@/components/ParkingDropdown.vue';
  import FilterButton from '@/components/FilterButton.vue';
  import SearchBar from '@/components/SearchBar.vue';
  import ReservationDetail from '@/components/ReservationDetail.vue';
  import ReservationTable from '@/components/ReservationTable.vue';
  import { getParkingList, getBusReserv } from '@/api/api';
  import { mapActions, mapGetters } from 'vuex';
import DateRangePicker from '@/components/DateRangePicker.vue';
import { formatDate } from '@/utils/dataFormatUtils';


  export default {
    components: {
      ReservationTable,
      ReservationDetail,
      ParkingDropdown,
      FilterButton,
      SearchBar,
      DateRangePicker
    },
    data() {
      return {
        columns: ["회원구분", "연락처", "예약 인원", "골프백 개수", "캐리어 개수", "승차 정류장", "하차 정류장", "상태"],      
        parkingLots: [],
        selectedReservation: null,
        reservations: [],
        activeFilter: 'ALL',
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),

        searchQuery: '',
        searchField: '',
        filters: [
          { label: '전체', value: 'ALL' },
          { label: '예약', value: 'RESERVED' },
          { label: '호출', value: 'CALLED' },
          { label: '배차완료', value: 'ACCEPTED' },
          { label: '셔틀탑승', value: 'RIDDEN' },
        ],
        searchFields: [
          { label: '승차정류장', value: 'getCall' },
          { label: '하차정류장', value: 'getArrive' },
          { label: '전화번호(뒤4자리)', value: 'customerPhone4' }
        ],
        periodOptions: [
          { label: "이번달", value: "thisMonth" },
          { label: "오늘", value: "today" },
          { label: "6개월", value: "6months" },
          { label: "1년", value: "1years" },
          { label: "직접입력", value: "custom" }
        ],
        
      };
    },
    created() {
      this.fetchParkingList().then(() => {
        if (this.selectedParkingLot) {
          this.fetchReservations(this.selectedParkingLot.id, "ALL");
        }
      });
    },
    computed: {
      ...mapGetters('parkingLot', ['selectedParkingLot']),
      filteredReservations() {
        let reservations = this.reservations;

        if (this.searchQuery && this.searchField) {
          const queryLower = this.searchQuery.toLowerCase();
          reservations = reservations.filter((reservation) =>
            String(reservation[this.searchField])
              .toLowerCase()
              .includes(queryLower)
          );
        }

        return reservations.map((reservation) => reservation.displayData);
      },
    },
    watch: {
      selectedParkingLot(newVal) {
        if (newVal) {
          this.activeFilter = 'ALL'; 
          this.selectedReservation = null
          this.fetchReservations(newVal.id, "ALL"); 
        }
      },
      activeFilter(newValue) {
        this.selectedReservation = null;
        this.fetchReservations(this.selectedParkingLot.id, newValue);
      }
    },
    methods: {
      ...mapActions('parkingLot', ['setselectParkingLot']),
      async fetchParkingList() {
        try {
          const parkingLots = await getParkingList();
          this.parkingLots = parkingLots;
          if (!this.selectedParkingLot) {
            await this.setselectParkingLot(this.parkingLots[0]); 
          }
        } catch (error) {
          console.error(error);

        }
      },
      async handlePeriodSearch({ startDate, endDate }) {
        this.startDate = startDate
        this.endDate = endDate
        const startDay = formatDate(startDate);
        const endDay = formatDate(endDate);
        console.log(startDay, endDay)
        await this.fetchReservations(this.selectedParkingLot.id, this.activeFilter, startDay, endDay);
      },
      async fetchReservations(parkingLotId, filter) {
        try {
          const reservations = await getBusReserv(parkingLotId, filter, formatDate(this.startDate), formatDate(this.endDate));
          this.reservations = reservations;
        } catch (error) {
          console.error(error);
        }
      },
      selectParkingLot(parkingLot) {
        this.setselectParkingLot(parkingLot);
      },
      clearReservation() {
        this.selectedReservation = null;
      },
      handleSearch({ query, field }) {
        this.searchQuery = query;
        this.searchField = field;
      },
      handleFieldChange(field) {
        this.searchField = field;
      },
    }
  };
</script>
  
<style>
  .table-container {
    flex: 2; /* 테이블이 더 큰 너비를 차지하도록 설정 */
    transition: flex 0.3s; /* 부드러운 전환 효과 추가 */
  }

  .table-selected {
    flex: 1.4; /* 선택된 상태에서 테이블의 너비를 줄임 */
  }

  .details-container {
    flex: 0.5;
    display: none;
    transition: flex 0.3s, display 0.3s; /* 부드러운 전환 효과 추가 */
  }

  .details-visible {
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: center;

  }
</style>