<template>
  <aside class="sidebar">
    <nav>
      <ul>
        <li v-for="section in menuSections" :key="section.title || section.name" class="menu-section">
          <div v-if="section.title" class="menu-title">{{ section.title }}</div>
          <ul class="no-margin">
            <li v-for="item in section.items || [section]" :key="item.path" class="menu-item">
              <router-link :to="item.path" active-class="active">
                <img v-if="item.icon" :src="getImageUrl(item.icon)" alt="" class="menu-icon"/>
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import { mapState } from 'vuex';
import { menuConfig } from '@/config/menuConfig';

export default {
  computed: {
    ...mapState({
      role: state => state.userInfo.role,
    }),
    menuSections() {
      return menuConfig[this.role] || [];
    }
  },
  methods: {
    getImageUrl(icon) {
      return require(`@/img/icons/${icon}`);
    }
  }
};
</script>


<style scoped>
.sidebar {
  width: 250px;
  background-color: var(--admin-main-color);
}

.menu-title {
  color: var(--admin-menu2-color);
  font-weight: bold;
  margin: 20px 0px 10px 10px;
  cursor: default;
}

.sidebar nav ul {
  list-style: none;
  margin: 18px;
  padding: 0px;
}

.sidebar nav ul li a {
  font-weight: bold;
  text-decoration: none;
  padding: 8px 5px;
  color: var(--admin-menu1-color);
  margin: 5px 0px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.sidebar nav ul li a.active {
  background-color: white;
  border-radius: 10px;
}

.menu-icon {
  margin: 0px 5px 0px 5px;
  width: 18px; /* 아이콘 크기 조정 */
  height: 18px; /* 아이콘 크기 조정 */
  vertical-align: middle; /* 아이콘을 텍스트 가운데에 맞추기 */
}
</style>