<template>
  <div>
    <h2 class="admin-title">관리자권한 관리</h2>
    <div class="content-container">
      <ManageTable 
        :APIDatas="APIDatas" 
        :columns="columns" 
        class="table-container"
        @delete-item="deleteItem"
        @edit-item="editItem"
        :showDeleteColumn="showDeleteColumn"
        style="width: 70%;" />
      <FormComponent
        :fields="formFields"
        submitButtonText="관리자 추가"
        @submit="handleFormSubmit"
        class="form-container"
        style="width: 30%;"
      />
      <ModalComponent
        v-if="showModal"
        :fields="formFields"
        :formData="currentEditData"
        submitButtonText="수정"
        @close="closeModal"
        @submit="handleEditSubmit"
      />
    </div>
  </div>
</template>

  
<script>
import ManageTable from '@/components/ManageTable.vue';
import FormComponent from '@/components/FormComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue'; // 모달 컴포넌트 추가

import { getManagers, postManagers, deleteManager } from '@/api/api';

export default {
  components: {
    ManageTable,
    FormComponent,
    ModalComponent
  },

  data() {
    return {
      columns: ["분류","관리자 이름", "관리 주차장"],
      APIDatas: [],
      formFields: [
        { name: 'name', label: '관리자 이름', type: 'text', placeholder: '' },
        { name: 'managerPhone', label: '관리자 연락처', type: 'text', placeholder: '숫자만 입력해주세요' },
        { name: 'managerId', label: '관리자 ID', type: 'text', placeholder: '' },
        { name: 'password', label: '비밀번호', type: 'text', placeholder: '' },
        { name: 'role', label: '관리자 분류', type: 'select', placeholder: '' , selectList: ['최고관리자','일반관리자', '운행사']},
      ],
      showModal: false, // 모달 표시 상태
      currentEditData: {}, // 현재 편집 중인 데이터
    };
  },

  methods: {
    async fetchManagersData() {
      try {
        const APIDatas = await getManagers();
        this.APIDatas = APIDatas.map(data => data.displayData);
      } catch (error) {
        console.error('Error fetching parking lots:', error);
      }
    },
    async handleFormSubmit(formData) {
      
      if (formData instanceof SubmitEvent) {
        console.warn('Invalid form data:', formData);
        return;
      }     
      
      try {
        const dataToSend = {
          managerId: formData.managerId,
          managerPhone: formData.managerPhone,
          name: formData.name,
          password: formData.password,
          role: formData.role,
        };

        await postManagers(dataToSend);
        this.fetchManagersData();
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
    
    async deleteItem(index) {
      const managerId = this.APIDatas[index].id;
      try {
        await deleteManager(managerId);
        this.APIDatas.splice(index, 1);
      } catch (error) {
        console.error('Error deleting reservation:', error);
      }
    },

    editItem(index) {
      this.currentEditData = { ...this.APIDatas[index] };
      // this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },
  },
  created() {
    this.fetchManagersData();
  }
};
</script>