export function formatDate(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

export function formatTime(date) {
    const d = new Date(date);
    const optionsDate = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'short'
    };
    const optionsTime = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    };

    const formattedDate = new Intl.DateTimeFormat('ko-KR', optionsDate).format(d);
    const formattedTime = new Intl.DateTimeFormat('ko-KR', optionsTime).format(d);

    const [year,Month,Day, weekday] = formattedDate.split(' ');
    const formattedDateWithWeekday = `${year} ${Month} ${Day} (${weekday})`;

    return `${formattedDateWithWeekday} ${formattedTime}`;
}

export function formatTimeString(timeStr) {
    if (timeStr == null) {
      return '';
    }
    
    const hours = timeStr.substring(0, 2);
    const minutes = timeStr.substring(2, 4);
    return `${hours}:${minutes}`;
  
}

export function formatCurrency(value) {
  if (typeof value !== 'number') {
    return value;
  }
  return value.toLocaleString('ko-KR');
}

export function parseCurrency(value) {
  if (typeof value !== 'string') {
    return value;
  }
  // 문자열에서 콤마(,)를 제거하고 숫자로 변환
  return parseFloat(value.replace(/,/g, ''));
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return phoneNumber;
  }
  if (/^\d{3}-\d{4}-\d{4}$/.test(phoneNumber) ) {
    return phoneNumber;
  }
  const formattedNumber = phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  return formattedNumber;
}

export function EngToKorean(org) {
  const EngMapping = {
    "RESERVED": "예약완료",
    "PAYMENT_COMPLETE": "결제완료",
    "USING": "주차중",
    "COMPLETED": "출차완료",
    "CANCELED": "예약취소",

    "APP": "모바일 앱",
    "MOWEB": "모바일 웹",
    "WEB": "웹페이지",

    "OVERSEAS": "국제선",
    "DOMESTIC": "국내선",

    "false": "미보관",
    "true": "보관"
  };
  return EngMapping[org] || org; // 매핑이 없는 경우 원래 값을 반환
}
