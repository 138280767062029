<template>
    <div>
      <h2 class="admin-title">정류장 관리</h2>
      <div class="content-container">
        <ManageTable 
          v-if="APIDatas.length > 0"
          :APIDatas="APIDatas" 
          :columns="columns" 
          class="table-container"
          @row-click="modifyData"
          @delete-item="deleteItem"
          :showDeleteColumn="true"
          style="width: 80%;" />
        <FormComponent
          :fields="formFields"
          submitButtonText="정류장 등록"
          @submit="handleFormSubmit"
          class="form-container"
          style="width: 20%;"
        />
      </div>
      <ModifyStation
        v-if="showModify"
        :data = "selectedData"
        :isVisible="showModify"
        :airport-list="getIdNamePairs"
        @close="closeModify"
      />
    </div>
  </template>
  
    
  <script>
  import ManageTable from '@/components/ManageTable.vue';
  import FormComponent from '@/components/FormComponent.vue';
  import { getAirPort, getStation, postStation, deleteStation } from '@/api/api';
  import ModifyStation from '@/components/ModifyStation.vue';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      ManageTable,
      FormComponent,
      ModifyStation
    },
  
    data() {
      return {
        columns: ["정류장", "주소", "위도", "경도"],
        APIDatas: [],
        formFields: [
          { name: 'name', label: '정류장', type: 'text', placeholder: '' },
          { name: 'address1', label: '주차장 주소', type: 'address', placeholder: '시/도' },
          { name: 'lat', label: '위도', type: 'text', placeholder: '' },
          { name: 'lng', label: '경도', type: 'text', placeholder: '' },
          { name: 'contact', label: '연락처', type: 'text', placeholder: '숫자만 기입해주세요' },
          { name: 'type', label: '정류장 타입', type: 'text', placeholder: '' },
          { name: 'radius', label: '반지름', type: 'text', placeholder: '' },
          { name: 'airportId', label: '근처공항', type: 'select', placeholder: '' },
        ],
        showModify: false,
        selectedData: null
      };
    },
    computed: {
      ...mapGetters('airport', ['airportList', 'getIdNamePairs']),
    },
    methods: {
      ...mapActions('airport', ['setairportlist']),
    async fetchAirport() {
      const data = await getAirPort();
      await this.setairportlist(data); 
    },
      async fetchStationData() {
        try {
          const APIDatas = await getStation();
          this.APIDatas = APIDatas.map(data => data.displayData);  
        } catch (error) {
          console.error('Error fetching parking lots:', error);
        }
      },
      async handleFormSubmit(formData) {
        if (formData instanceof SubmitEvent) {
          console.warn('Invalid form data:', formData);
          return;
        }
  
        try {
          const dataToSend = {
            name: formData.name,
            sido: formData.sidoname,
            sigungu: formData.sigunguname,
            dong: formData.eupmyundongname,
            detailAddress: formData.detailname,
            lat: parseFloat(formData.lat),
            lng: parseFloat(formData.lng),
            type: formData.type,
            radius: parseInt(formData.radius),
            airportId: formData.airportId,
          };
  
          await postStation(dataToSend);
          this.fetchStationData();
          alert(`${dataToSend.name}이 성공적으로 등록되었습니다.`);
  
        } catch (error) {
          console.error('Error submitting form:', error);
          alert('등록 중 오류가 발생했습니다. 다시 시도해주세요.');
        }
      },
      async deleteItem(index) {
        const ID = this.APIDatas[index].No;
        const Name = this.APIDatas[index].정류장;
  
        // 확인 팝업 띄우기
        const confirmed = confirm(`${Name}을 삭제하시겠습니까?`);
  
        if (confirmed) {
          try {
            await deleteStation(ID);
            this.APIDatas.splice(index, 1);
          } catch (error) {
            console.error('Error deleting reservation:', error);
            alert('삭제 중 오류가 발생했습니다. 다시 시도해주세요.');
  
          }
        } else {
          console.log('cancelled');
        }
      },
      modifyData(data) {
        this.showModify = true;
        console.log("managestation",data)
        this.selectedData = data
      },
      closeModify() {
        this.showModify = false;
        this.fetchStationData();
      },
    },
    created() {
      this.fetchStationData().then(() => {
        // 주차장이 선택된 후에 예약 목록을 가져옵니다.
        this.fetchAirport();
      });
    },
    mounted() {
      // 컴포넌트가 마운트될 때 selectList를 업데이트
      this.formFields.find(field => field.name === 'airportId').selectList = this.getIdNamePairs;
    }
  
  };
  </script>