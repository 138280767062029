<template>
  <div class="private-policy-page">
    <PrivatePolicy />
  </div>
  </template>
  
<script>
  import PrivatePolicy from '@/components/PrivatePolicy.vue'
  
  export default {
    components: {
      PrivatePolicy
    }
  }
</script>
