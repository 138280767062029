import { formatTime, formatPhoneNumber } from "@/utils/dataFormatUtils";

class VehicleInfo {
  constructor(number, type) {
    this.number = number;
    this.type = type;
  }
}

class VehicleInfoList {
  constructor(list = []) {
    this.list = list.map(item => new VehicleInfo(item.number, item.type));
  }

  get displayList() {
    return this.list.map(item => `${item.type} ${item.number}`).join(' / ');
  }
}


export class customerDTO {
  constructor({ id, customerPhone, provide, providedAt, vehicleInfoList }) {
    this.id = id;
    this.customerPhone = customerPhone;
    this.provide = provide;
    this.providedAt = providedAt;
    this.vehicleInfoList =  new VehicleInfoList(vehicleInfoList.list);
  }


  get displayData() {
    return {
      "No": this.id,
      "연락처": formatPhoneNumber(this.customerPhone),
      "제3자 제공 동의 여부": this.providetoKorean(this.provide, this.providedAt),
      "보유차량": this.vehicleInfoList.displayList
    };
  }

  providetoKorean(val, at) {
    if (val === true) {
      at = formatTime(at)
      return `동의 (${at})`
    } else {
      return "미동의"
    }

  }
}

