<template>
  <div class="date-range-picker">
    <span class="date-picker-label">기간</span>
    <ul class="period-options">
      <li v-for="option in periodOptions" :key="option.value" :class="{ active: activePeriod === option.value }" @click="setPeriod(option.value)">
        {{ option.label }}
      </li>
    </ul>
    <li class="custom-dates" :class="{ visible: activePeriod === 'custom' }">
      <input type="date" v-model="customStartDate" placeholder="시작 날짜" />
      ~
      <input type="date" v-model="customEndDate" placeholder="종료 날짜" />
    </li>
    <div class="button-group">
    <button class="submit-button no-margin" @click="search">조회하기</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // periodOptions: [
      //   { label: "이번달", value: "thisMonth" },
      //   { label: "오늘", value: "today" },
      //   { label: "1개월", value: "1month" },
      //   { label: "3개월", value: "3months" },
      //   { label: "6개월", value: "6months" },
      //   { label: "1년", value: "1years" },
      //   { label: "직접입력", value: "custom" }
      // ],
      activePeriod: "today",
      customStartDate: "",
      customEndDate: ""
    };
  },
  props: {
    periodOptions: {
      type: Array,
      Required: true
    }
  },
  methods: {
    setPeriod(period) {
      this.activePeriod = period;
      if (period !== "custom") {
        this.customStartDate = "";
        this.customEndDate = "";
      }
    },
    search() {
      let startDate, endDate;

      switch (this.activePeriod) {
        case "thisMonth":
          startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
          endDate = new Date();
          break;
        case "today":
          startDate = new Date();
          endDate = new Date();
          break;
        case "1month":
          startDate = new Date();
          startDate.setMonth(startDate.getMonth() - 1);
          endDate = new Date();
          break;
        case "3months":
          startDate = new Date();
          startDate.setMonth(startDate.getMonth() - 3);
          endDate = new Date();
          break;
        case "6months":
          startDate = new Date();
          startDate.setMonth(startDate.getMonth() - 6);
          endDate = new Date();
          break;
        case "1years":
          startDate = new Date();
          startDate.setYear(startDate.getFullYear() - 1);
          endDate = new Date();
          break;
        case "custom":
          startDate = this.customStartDate;
          endDate = this.customEndDate;
          break;
        case "currentMonth":
          startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
          endDate = new Date(new Date().getFullYear(), new Date().getMonth()+1, 1);
          break;
        case "after6months":
          startDate = new Date();
          endDate = new Date();
          endDate.setMonth(endDate.getMonth() + 6);
          break;
        case "after1years":
          startDate = new Date();
          endDate = new Date();
          endDate.setYear(startDate.getFullYear() + 1);
          break;
      }

      this.$emit('search', { startDate, endDate });
    },
    reset() {
      this.activePeriod = 'thisMonth';
    }
  }
};
</script>

<style scoped>
.date-range-picker {
  display: flex;
  align-items: center;
  background-color: var(--admin-main-color);
  padding: 10px;
  margin: 10px 0px;
  border-radius: 10px;
  justify-content: space-between;
}

.date-picker-label {
  font-weight: bold;
  margin: 0px 20px;
}

.period-options {
  display: flex;
  list-style: none;
  margin: 0px;
  cursor: pointer;
}

.period-options li {
  margin: 0px 10px;
  padding:5px 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.period-options li.active {
  background-color: #fcfaff;
  text-align: center;
}

.period-options li input {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.custom-dates {
  display: none;
  padding: 0px 10px;
}

.custom-dates.visible {
  display: flex;
  align-items: center;
}

.custom-dates input {
  padding: 5px;
  margin: 0px 10px;
}

.button-group {
  margin-left: auto;
}

</style>
