import { formatCurrency, formatPhoneNumber, formatTimeString } from "@/utils/dataFormatUtils";

export class parkingListDTO {
  constructor({ id, parkingAreaName, defaultCharge }) {
    this.id = id;
    this.name = parkingAreaName;
    this.defaultCharge = defaultCharge;
  }
}

export class parkingLotDTO {
  constructor({ id, name,seats,sido,sigungu,dong,detailAddress,lat,lng,contact,openTime,closeTime,airportId,callPay, minPay }) {
    this.id = id;
    this.name = name;
    this.seats = seats;
    this.sido = sido;
    this.sigungu = sigungu;
    this.dong = dong;
    this.detailAddress = detailAddress;
    this.lat = lat;
    this.lng = lng;
    this.contact = contact;
    this.openTime = openTime;
    this.closeTime = closeTime;
    this.airportId = airportId;
    this.callPay = callPay;
    this.minPay = minPay;
  }

  get displayData() {
    return {
      "No": this.id,
      "주차장명": this.name,
      "주차면 수": this.seats,
      "시도": this.sido,
      "시군구": this.sigungu,
      "동": this.dong,
      "상세주소": this.detailAddress,
      "주소": `${this.sido} ${this.sigungu} ${this.dong} ${this.detailAddress}`,
      "연락처": formatPhoneNumber(this.contact),
      "영업 시간": `${formatTimeString(this.openTime)} ~ ${formatTimeString(this.closeTime)}`,
      "시작시간": `${formatTimeString(this.openTime)}`,
      "종료시간": `${formatTimeString(this.closeTime)}`,
      "공항": this.airportId,
      "위도": this.lat,
      "경도": this.lng,
      "최소금액": `${formatCurrency(this.minPay)}원`,
      "버스금액": `${formatCurrency(this.callPay)}원`,
      "callPay": this.callPay,
      "minPay": this.minPay
    };
  }

}

export class requestParkingLotDTO {
  constructor(name, seats, sido, sigungu, dong, detailAddress, lat, lng, contact, openTime, closeTime) {
    this.name = name;
    this.seats = seats;
    this.sido = sido;
    this.sigungu = sigungu;
    this.dong = dong;
    this.detailAddress = detailAddress;
    this.lat = lat;
    this.lng = lng;
    this.contact = contact;
    this.openTime = openTime;
    this.closeTime = closeTime;
  }
}

export class parkingFeeDTO {
  constructor({date, dayFee}) {
    this.date = date;
    this.dayFee = formatCurrency(dayFee);
  }
}
  
export class surchargeDTO {
  constructor(data) {
    this.id= data.id;
    this.startDay = data.startDay;
    this.endDay = data.endDay;
    this.surcharge = data.surcharge;
    this.discount = data.discount;
  }
}
  


export class parkingSalesDTO {
  constructor(data) {
    this.total = data.total;
    this.vat = data.vat;
    this.pgCharge = data.pgCharge;
    this.platformCharge = data.platformCharge;
    this.netProfit = data.netProfit;
    this.details = data.details.map(detail => new salesDetailDTO(detail.total, detail.reservationId, detail.orderId, detail.payTime));
  }
}

// Define the Detail DTO
class salesDetailDTO {
  constructor(total, reservationId, orderId, payTime) {
    this.total = total;
    this.reservationId = reservationId;
    this.orderId = orderId;
    this.payTime = payTime;
  }
  get displayData() {
    return {
      "결제금액": `${formatCurrency(this.total)} 원`,
      "예약번호":this.orderId,
      "결제일": this.formatpayTime(this.payTime),
    };
  }

  formatpayTime(payTime) {
    const date = payTime.substring(0, 8);
    const time = payTime.substring(8);
    const formattedDate = date.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    const formattedTime = time.replace(/(\d{2})(\d{2})(\d{2})/, '$1:$2:$3');
    return `${formattedDate} ${formattedTime}`;
  }
}

