<template>
  <h2 class="admin-title">매출 통계</h2>
  <div v-if="parkingLots.length > 0"> 
    <ParkingDropdown 
      :items="parkingLots" 
      :defaultText="selectedParkingLot.name" 
      @change="selectParkingLot"/>
    <div>
      <DateRangePicker ref="dateRangePicker" @search="handleSearch" :periodOptions="periodOptions"/>
    </div>
    <div class="totals-container">
      <div class="payment-section">
        <h3>총 결제금액</h3>
        <p>{{ formatCurrency(total)}}</p>
      </div>
      <div class="payment-section">
        <h3>PG사 결제 수수료</h3>
        <p>{{ formatCurrency(pgCharge)}}</p>
      </div>
      <div class="payment-section">
        <h3>플랫폼 수수료</h3>
        <p>{{ formatCurrency(platformCharge)}}</p>
      </div>
      <div class="payment-section">
        <h3>정산 금액</h3>
        <p>{{ formatCurrency(netProfit)}}</p>
      </div>
    </div>
    <div style="height: 75%;">∂
      <h2 class="admin-title">조회 결과</h2>
      <ManageTable 
        :APIDatas="APIDatas" 
        :columns="columns" 
        class="table-container"
        :showDeleteColumn=false
        />
    </div>
  </div>
  <div v-else class="empty-container">
    <EmptyParkinglot/>
  </div>
</template>

<script>
  import ParkingDropdown from '@/components/ParkingDropdown.vue';
  import ManageTable from '@/components/ManageTable.vue';
  import DateRangePicker from '@/components/DateRangePicker.vue';
  import {parkingSalesDTO} from '@/api/dto/parkingLotDTO'
  import { getParkingList, getSales} from '@/api/api';
  import EmptyParkinglot from '@/components/EmptyParkinglot.vue';
  import { formatDate } from '@/utils/dataFormatUtils';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    components: {
      ParkingDropdown,
      DateRangePicker,
      ManageTable,
      EmptyParkinglot
    },
    data() {
      return {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        parkingLots: [],
        total: 0,  
        vat: 0,
        pgCharge: 0,
        platformCharge: 0,
        netProfit: 0,
        columns: ["예약번호","결제금액", "결제일"],
        APIDatas: [],
        periodOptions: [
          { label: "이번달", value: "thisMonth" },
          { label: "오늘", value: "today" },
          { label: "1개월", value: "1month" },
          { label: "3개월", value: "3months" },
          { label: "6개월", value: "6months" },
          { label: "1년", value: "1years" },
          { label: "직접입력", value: "custom" }
        ],
      };
    },
    computed: {
      ...mapGetters('parkingLot', ['selectedParkingLot']),
    },
    created() {
      this.fetchParkingList();
    },
    watch: {
      selectedParkingLot(newVal) {
        console.log(newVal, 'sdfsdf')
        if (newVal) {
          this.$refs.dateRangePicker.reset();
          this.fetchSalesData(formatDate(this.startDate), formatDate(this.endDate));
        }
      },
    },
    methods: {
      ...mapActions('parkingLot', ['setselectParkingLot']),
      async fetchParkingList() {
        try {
          const parkingLots = await getParkingList();
          this.parkingLots = parkingLots;
          if (!this.selectedParkingLot) {
            await this.setselectParkingLot(this.parkingLots[0]); 
          }
          this.fetchSalesData(formatDate(this.startDate), formatDate(this.endDate));
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      },
      selectParkingLot(parkingLot) {
        this.setselectParkingLot(parkingLot);
      },
      async handleSearch({ startDate, endDate }) {
        const startDay = formatDate(startDate);
        const endDay = formatDate(endDate);
        await this.fetchSalesData(startDay, endDay);
        alert(`${startDay}~${endDay} 기간의 매출이 조회되었습니다.`)
      },
      async fetchSalesData(startDate, endDate) {
        try {
          const response = await getSales(this.selectedParkingLot.id, startDate, endDate);

          this.total = response.total;
          this.vat = response.vat;
          this.pgCharge = response.pgCharge;
          this.platformCharge = response.platformCharge;
          this.netProfit = response.netProfit;
          const salesData = new parkingSalesDTO(response);
          this.APIDatas = salesData.details.map(detail => detail.displayData);
        } catch (error) {
          console.error('Error fetching parking lots:', error);
          alert(`매출조회중 에러가 발생했습니다. 다시 시도해주세요.`);
        }
      },
      formatCurrency(value) {
        if (typeof value !== "number") {
          return value;
        }
        return value.toLocaleString('ko-KR') + '원';
      }
    },
  }
</script>

<style>
  .totals-container {
    display: flex;
    justify-content: space-between;
    margin: 20px -10px;
  }

  .payment-section {
    flex: 1;
    padding: 20px;
    margin: 0px 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    text-align: center;
  }

  .payment-section h3 {
    font-weight: bold;
    margin: 10px 0px;
  }

  .payment-section p {
    font-weight: bold;
    margin: 10px 0px;
    font-size: 1.1rem;
  }

</style>
