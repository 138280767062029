<template>
  <div class="filter-buttons">
    <button
      v-for="filter in filters" 
      :key="filter.value" 
      :class="{ active: modelValue === filter.value }"
      @click="updateFilter(filter.value)">
      {{ filter.label }}
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      filters: {
        type: Array,
        required: true
      },
      modelValue: {
        type: String,
        required: true
      }
    },
    methods: {
      updateFilter(value) {
        this.$emit('update:modelValue', value);
      }
    }
  };
</script>
  
<style>
  .filter-buttons {
    width: fit-content;
    display: flex;
    background-color: var(--admin-main-color);
    border-radius: 10px;
    padding: 5px;
    margin: 5px 0px;
  }
  
  .filter-buttons button {
    background: none;
    border: none;
    color: var(--admin-button-color);
    padding: 5px 20px;
    cursor: pointer;
    border-radius: 10px;
    font-weight: bold;
  }
  
  .filter-buttons button.active {
    background-color: white;
    color: var(--admin-button-color);
  }
  
  .filter-buttons button:not(.active):hover {
    background-color: var(--admin-hover-color);
  }
</style>
  