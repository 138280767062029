<template>
  <div class="table-container no-margin">
    <table>
      <thead>
        <tr>
          <th v-for="(column, index) in columns" :key="index">{{ column }}</th>
        </tr>
      </thead>
      <tr
        v-for="reservation in paginatedData"
        :key="reservation.id"
        @click="handleRowClick(reservation)"
        :class="{ 'selected-row': reservation === selectedReservation }">
      <td
        v-for="column in columns" 
        :key="column"
        :class="getColor(reservation[column])">
        {{ reservation[column] }}
      </td>
      </tr>
    </table>
    <TablePagination 
      :currentPage="currentPage" 
      :totalPages="totalPages" 
      @page-changed="handlePageChange" 
    />
  </div>

</template>

<script>
import TablePagination from '@/components/TablePagination.vue';

export default {
  components: {
    TablePagination,
  },
  props: {
    reservations: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    selectedReservation: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 12
    };
  },
  computed: {
    paginatedData() {
      if (!this.reservations || this.reservations.length === 0) {
        return [];
      }
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.reservations.slice(start, end);
    },
    totalPages() {
      if (!this.reservations || this.reservations.length === 0) {
        return 1;
      }
      return Math.ceil(this.reservations.length / this.itemsPerPage);
    }
  },
  methods: {
    handleRowClick(reservation) {
      this.$emit('row-click', reservation);
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    getColor(value) {
      // 예를 들어 값이 100보다 크면 빨간색으로 변경
      if (value === "답변 대기") {
        return 'highlight-red';
      }
      return ''; // 기본 클래스
    },
  }
}
</script>

<style>
.highlight-red {
  color: red;
}
.table-container {
  flex: 1;
  overflow-x: auto;
  overflow-y: auto;
}

.selected-row {
  background-color: var(--admin-main-color);
}

</style>