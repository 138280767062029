<template>
  <div class="admin-page">
    <h2 class="admin-title">주차장 예약 리스트</h2>
    <div v-if="loading"></div>
    <div v-else class="admin-page">
      <div v-if="parkingLots.length > 0"> 
        <div class="filter-search-container">
          <ParkingDropdown :items="parkingLots" :defaultText="selectedParkingLot.name" @change="selectParkingLot"/>          
          <SearchBar
            v-model="searchQuery"
            :field="searchField"
            :fields="searchFields"
            @search="handleSearch"
            @field-change="handleFieldChange"/>
        </div>
        <div class="filter-search-container">
          <FilterButton v-model="activeFilter" :filters="filters" />
          <DateRangePicker ref="dateRangePicker" @search="handlePeriodSearch" :periodOptions="periodOptions" />
        </div>
        <div class="content-container no-margin-padding">
          <div ref="tableContainer" :class="['table-container', { 'table-selected': selectedReservation }]">
            <ReservationTable
              v-if="selectedParkingLot"
              :reservations="filteredReservations.map((reservation) => reservation.displayData)"
              :columns="columns"
              @row-click="selectReservation"
              :selectedReservation="selectedReservation"/>
          </div>
        </div>
      </div>
      <div v-else class="empty-container">
        <EmptyParkinglot/>
      </div>
    </div>
    <ImageModal
      v-if="showModal"
      :reservId="photoId"
      :isVisible="showModal"
      @close="closeModal"
    />
    <ReservationDetailSidebar
      v-if="showVal"
      :parkingList="selectedReservation"
      :parkingLotName="selectedParkingLot.name" 
      @close="closeDetail"
      @photo="viewPhoto"
    />
  </div>      
</template>
  
<script>
  import ParkingDropdown from '@/components/ParkingDropdown.vue';
  import FilterButton from '@/components/FilterButton.vue';
  import SearchBar from '@/components/SearchBar.vue';
  import ReservationTable from '@/components/ReservationTable.vue';
  import EmptyParkinglot from '@/components/EmptyParkinglot.vue'
  import { getParkingList, getParkingReserv } from '@/api/api';
  import ImageModal from '@/components/ImageModal.vue';
  import { mapActions, mapGetters } from 'vuex';
  import ReservationDetailSidebar from '@/components/ReservationDetailSidebar.vue';
  import DateRangePicker from '@/components/DateRangePicker.vue';
  import { formatDate } from '@/utils/dataFormatUtils';

  export default {
    components: {
      ReservationTable,
      ParkingDropdown,
      FilterButton,
      DateRangePicker,
      SearchBar,
      EmptyParkinglot,
      ImageModal,
      ReservationDetailSidebar
    },
    data() {
      return {
        loading: true,
        columns: ["회원구분","예약일", "이용일", "차량 정보", "총 금액", "예약상태"],      
        parkingLots: [],
        selectedReservation: null,
        reservations: [],
        activeFilter: 'ALL',
        searchQuery: '',
        searchField: '',

        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        filters: [
          { label: '전체', value: 'ALL' },
          { label: '예약완료', value: 'RESERVED' },
          { label: '출차완료', value: 'COMPLETED' },
          { label: '예약취소', value: 'CANCELED' }
        ],
        searchFields: [
          { label: '차종', value: 'vehicleType' },
          { label: '차량번호', value: 'vehicleNumber' },
          { label: '전화번호(뒤4자리)', value: 'customerPhone4' }
        ],

      periodOptions: [
        { label: "이번달", value: "currentMonth" },
        { label: "오늘", value: "today" },
        { label: "6개월", value: "after6months" },
        { label: "1년", value: "after1years" },
        { label: "직접입력", value: "custom" }
      ],

        showVal: false,
        showModal: false,
        photoId: null,
      };
    },
    created() {
      this.fetchParkingList().then(() => {
        // 주차장이 선택된 후에 예약 목록을 가져옵니다.
        if (this.selectedParkingLot) {
          this.fetchReservations(this.selectedParkingLot.id, "ALL", formatDate(this.startDate), formatDate(this.endDate) );
        }
      });
    },
    computed: {
      ...mapGetters('parkingLot', ['selectedParkingLot']),
      filteredReservations() {
        let reservations = this.reservations;

        if (this.searchQuery && this.searchField) {
          const queryLower = this.searchQuery.toLowerCase();
          reservations = reservations.filter((reservation) =>
            String(reservation[this.searchField])
              .toLowerCase()
              .includes(queryLower)
          );
        }

        return reservations;
      },
    },
    watch: {
      selectedParkingLot(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.activeFilter = 'ALL';
          this.selectedReservation = null
          this.$refs.dateRangePicker.reset();
          this.fetchReservations(newVal.id, "ALL", formatDate(this.startDate), formatDate(this.endDate) );
        }
      },
      activeFilter(newValue) {
        this.selectedReservation = null; 
        this.fetchReservations(this.selectedParkingLot.id, newValue, formatDate(this.startDate), formatDate(this.endDate) );
      }
    },
    methods: {
    ...mapActions('parkingLot', ['setselectParkingLot']),

      async fetchParkingList() {
        try {
          const parkingLots = await getParkingList();
          this.parkingLots = parkingLots;
          if (!this.selectedParkingLot) {
            await this.setselectParkingLot(this.parkingLots[0]); 
            console.log('==>', this.selectedParkingLot, this.parkingLots[0]);
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      },
      async handlePeriodSearch({ startDate, endDate }) {
        this.startDate = startDate
        this.endDate = endDate
        const startDay = formatDate(startDate);
        const endDay = formatDate(endDate);
        console.log(startDay, endDay)
        await this.fetchReservations(this.selectedParkingLot.id, this.activeFilter, startDay, endDay);
      },
      async fetchReservations(parkingLotId, filter, start, end) {
        try {
          const reservations = await getParkingReserv(parkingLotId, filter, start, end);
          this.reservations = reservations;
        } catch (error) {
          console.error(error);
        }
      },
      selectParkingLot(parkingLot) {
        this.setselectParkingLot(parkingLot);
      },
      selectReservation(reservation) {
        this.showVal = true;
        console.log('행선택', reservation);
        this.selectedReservation = reservation;
      },
      clearReservation() {
        this.selectedReservation = null;
      },
      handleClickOutside(event) {
        const reservationDetail = this.$refs.reservationDetail;
        const tableContainer = this.$refs.tableContainer;
        if (reservationDetail && !reservationDetail.$el.contains(event.target) &&
        tableContainer && !tableContainer.contains(event.target)) {
          this.clearReservation();
        }
      },
      handleSearch({ query, field }) {
        this.searchQuery = query;
        this.searchField = field;
      },
      handleFieldChange(field) {
        this.searchField = field;
      },
      viewPhoto(reservId) {
        this.photoId = reservId;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false
      },
      closeDetail() {
        this.showVal = false
      }
    
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleClickOutside);
    }
  };
</script>
  
<style>
  .filter-search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .table-container {
    flex: 2; /* 테이블이 더 큰 너비를 차지하도록 설정 */
    transition: flex 0.3s; /* 부드러운 전환 효과 추가 */
  }

  .table-selected {
    flex: 1.4; /* 선택된 상태에서 테이블의 너비를 줄임 */
  }

  .details-container {
    flex: 0.5;
    display: none;
    transition: flex 0.3s, display 0.3s; /* 부드러운 전환 효과 추가 */
  }

  .details-visible {
    flex: 0;
    display: flex;
    align-items: center; /* 수직 가운데 정렬 */
    justify-content: center; /* 수평 가운데 정렬 */

  }
</style>