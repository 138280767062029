<template>
    <div 
      class="backDrop" 
      @click="closeDetail" >
    <div class="sidebar" >
      <!-- 예약 상세 정보 내용 -->
      <div class="p-1" v-if="localParkingList">
        <button @click="closeDetail" class="close-button">X</button>
        <div class="detail-header">
          <div class="status">
            <img :src="statusIcon" :alt="localParkingList['예약상태']" class="status-icon" />
            <div class="reservation-info">
              <span class="parking-lot">{{ parkingLotName }}</span>
              <span class="dates">{{ localParkingList["이용일"] }}</span>
            </div>
          </div>
        </div>
        <hr class="detail-separator" >
        <div class="content">
          <h3 class="no-margin-padding">예약 정보</h3>
          <div class="info-row">
            <span class="label">회원구분</span>
            <span class="value">{{ localParkingList["회원구분"] }}</span>
          </div>
          <div class="info-row">
            <span class="label">연락처</span>
            <span class="value">{{ localParkingList["연락처"] }}</span>
          </div>
          <div class="info-row">
            <span class="label">입차시간</span>
            <span class="value">{{ localParkingList["입차 시간"] }}</span>
          </div>
          <div class="info-row">
            <span class="label">출차시간</span>
            <span class="value">{{ localParkingList["출차 시간"] }}</span>
          </div>
          <div class="info-row">
            <span class="label">차량 정보</span>
            <span class="value">{{ localParkingList["차량 정보"] }}</span>
          </div>
          <div class="info-row">
            <span class="label">예약 인원</span>
            <span class="value">{{ localParkingList["예약 인원"] }}</span>
          </div>
          <div class="info-row">
            <span class="label">항공타입</span>
            <span class="value">{{ localParkingList["항공타입"] }}</span>
          </div>
          <div class="info-row">
            <span class="label">캐리어 수량</span>
            <span class="value">{{ localParkingList["캐리어 수량"] }}</span>
          </div>
          <div class="info-row">
            <span class="label">골프백 수량</span>
            <span class="value">{{ localParkingList["골프백 수량"] }}</span>
          </div>
          <div class="info-row">
            <span class="label">예약 플랫폼</span>
            <span class="value">{{ localParkingList["예약 플랫폼"] }}</span>
          </div>
          <div class="info-row">
            <span class="label">총 금액</span>
            <span class="value">{{ localParkingList["총 금액"] }}</span>
          </div>
          <div class="info-row">
            <span class="label">예약일</span>
            <span class="value">{{ localParkingList["예약일"] }}</span>
          </div>

        <div class="button-row no-margin-padding" v-if="localParkingList['예약상태'] === '주차중' || localParkingList['예약상태'] === '출차완료'">    
          <button class="photo-button" @click="openPhoto">차량 촬영 사진 보기</button>
        </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    parkingLotName: {
      type: String,
      required: true
    },
    parkingList: { 
      type: Object,
      required: true
    }
  },
  watch: {
    parkingList: {
      handler(newValue) {
        console.log(this.localParkingList, "!@#!@#")
        this.localParkingList = { ...newValue };  // 부모로부터 새로운 parkingList가 들어올 때 로컬 데이터 업데이트
        console.log('==> ',this.localParkingList)
      },
      immediate: true  // 컴포넌트가 생성될 때 처음으로 실행
    }
  },
  data() {
    return {
      localParkingList: { ...this.parkingList }  // parkingList를 로컬 데이터로 복사
    };
  },
  computed: {
    statusIcon() {
      const statusIcons = {
        '예약완료': require('@/img/ic_reserved.svg'),
        '주차중': require('@/img/ic_parking.svg'),
        '출차완료': require('@/img/ic_checkedout.svg'),
        '예약취소': require('@/img/ic_cancelled.svg')
      };
      return statusIcons[this.parkingList['예약상태']]|| require('@/img/logo.png');
    }
  },
  methods: {
    closeDetail() {
      this.$emit('close');
    },
    openPhoto() {
      const reservId = this.localParkingList['reservationId']
      this.$emit('photo', reservId);
    },
  }
};
</script>

<style scoped>
.backDrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px; /* 사이드바의 너비를 설정 */
  height: 100%;
  background-color: white;
  z-index: 1001; /* 다른 요소 위에 배치 */
  padding: 20px;
}
.close-button {
  font-size: 1.1rem;
  position: absolute;
  top: 15px;
  right: 15px;
}

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.detail-separator {
  border: 0;
  height: 1px;
  background: var(--admin-button-color);
  margin: 10px 0;
}
.status {
  width: 100%;
  display: flex;
  align-items: center;
}

.status-icon {
  width: 25%; /* 원하는 너비로 설정 */
}

.reservation-info {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.parking-lot {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: .5rem;
}

.dates {
  font-size: 1rem;
  color: gray;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.content {  
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.content h3 {
  font-size: 1rem;
}

.info-row {
  display: flex;
  position: relative;
  align-items: center; /* 수직 중앙 정렬 */
  margin: 10px 0;
}

.label {
  width: 35%;
  color: gray;
  font-size: 1rem;
}

.value {
  width: 65%;
  margin: 5px 0px;
  font-size: 1rem;
}

.v-line{
  border-left: 1px solid var(--admin-button-color);
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
 }

.button-row {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center;
  margin-top: 10px;
}

.photo-button {
  width: 100%;
  background: var(--admin-main-color); 
  font-size: 1rem;
  border-radius: 6px;
  border: none;
  padding: .8rem;
  margin-top: 1rem;
  cursor: pointer;
  color: var(--admin-menu1-color);
}
</style>

