<template>
    <div id="calendar">
      <div class="calendar-header">
        <button @click="prevMonth" >
          <img src="@/img/icons/ic_prevpage.svg" />
        </button>
        <span> {{ currentYear }}년 {{ currentMonthName }}</span>
        <button @click="nextMonth" :disabled="!canGoNextMonth">
          <img src="@/img/icons/ic_nextpage.svg" />
        </button>
      </div>
      <div class="calendar-grid">
        <div class="dayofweek" v-for="(day, index) in daysOfWeek" :key="day" :class="{'sunday': index === 0, 'saturday': index === 6}">
          {{ day }}
        </div>
        <div class="empty" v-for="blank in blanks" :key="'b' + blank"></div>
        <div class="day" 
          v-for="(date, index) in dates" 
          :key="date" 
          :class="getDateClass(index, date)"
          @mousedown="startDrag(date)"
          @mouseup="endDrag(date)"
          @mouseover="onMouseOver(date)"
          >
          <span class="date" :class="getDateClass(index)">{{ date }}</span>
          <span class='date-fee' v-if="feelist[formatDate(currentYear, currentMonth, date)]">
            {{ feelist[formatDate(currentYear, currentMonth, date)] }} 원
          </span>
        </div>
      </div>
      <div v-if="isModalOpen" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeModal">&times;</span>
          <h2 style="margin-bottom: 2rem">요금 변경</h2>
          <div class="date-range">
            <label for="startDate">시작일</label>
            <input type="date" v-model="startDate" />
            <label for="endDate">종료일</label>
            <input type="date" v-model="endDate" />
          </div>
          <div class="date-range">
            <label for="fee">
              요금</label>
            <input 
              type="text"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '')" 
              v-model="newFee" />
          </div>
          <button class='submit-button' @click="updateFee">저장</button>
        </div>
      </div>
    </div>
  </template>
  
<script>
  export default {
    props: {
      fees: {
        type: Array,
        required: true,
      }
    },
    data() {
      const today = new Date();
      return {
        today,
        currentYear: new Date().getFullYear(),
        currentMonth: new Date().getMonth(),
        currentDate: new Date().getDate(),
        daysOfWeek: ['일', '월', '화', '수', '목', '금', '토'],
        feelist : {},
        threeMonthsLater: new Date(today.getFullYear(), today.getMonth() + 3, 0),
        isModalOpen: false,
        newFee: null,
        selectedDate: null,
        startDate: '',
        endDate: '',
        isDragging: false,
        draggingDates: [],
      };
    },
    watch: {
      fees: {
        immediate: true,
        handler(newEvents) {
          this.feelist = newEvents.reduce((acc, fee) => {
            acc[fee.date] = fee.dayFee;
            return acc;
          }, {});
        }
      }
    },
    computed: {
      currentMonthName() {
        return new Date(this.currentYear, this.currentMonth).toLocaleString('default', { month: 'long' });
      },
      dates() {
        const daysInMonth = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
        return Array.from({ length: daysInMonth }, (_, i) => i + 1);
      },
      blanks() {
        const firstDay = new Date(this.currentYear, this.currentMonth, 1).getDay();
        return Array.from({ length: firstDay }, (_, i) => i);
      },
      canGoNextMonth() {
        const nextMonth = new Date(this.currentYear, this.currentMonth + 1);
        return nextMonth <= this.threeMonthsLater;
      }
    },
    methods: {
      prevMonth() {
        if (this.currentMonth === 0) {
          this.currentMonth = 11;
          this.currentYear--;
        } else {
          this.currentMonth--;
        }
      },
      nextMonth() {
        if (this.currentMonth === 11) {
          this.currentMonth = 0;
          this.currentYear++;
        } else {
          this.currentMonth++;
        }
      },
      getDateClass(index, date) {
        const dayOfWeek = (index + this.blanks.length) % 7;
        const formattedDate = this.formatDate(this.currentYear, this.currentMonth, date);
        const isToday = this.currentYear === this.today.getFullYear() && this.currentMonth === this.today.getMonth() && date === this.currentDate;

        return {
          'sunday': dayOfWeek === 0,
          'saturday': dayOfWeek === 6,
          'today': isToday,
          'dragging': this.draggingDates.includes(formattedDate),
          'dragging-start': formattedDate === this.draggingDates[0],
          'dragging-end': formattedDate === this.draggingDates[this.draggingDates.length - 1],
          'dragging-middle': this.draggingDates.includes(formattedDate) && this.draggingDates.length > 1 
          && formattedDate !== this.draggingDates[0] && formattedDate !== this.draggingDates[this.draggingDates.length - 1]
        };
      },
      formatDate(year, month, day) {
        return `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      },
      startDrag(date) {
        this.isDragging = true;
        const formattedDate = this.formatDate(this.currentYear, this.currentMonth, date);
        this.startDate = formattedDate;
        this.endDate = formattedDate;
        this.draggingDates = [formattedDate];
      },
      endDrag(date) {
        if (this.isDragging) {
          const formattedDate = this.formatDate(this.currentYear, this.currentMonth, date);
          this.endDate = formattedDate;
          this.isDragging = false;
          this.isModalOpen = true;
        }
      },
      onMouseOver(date) {
        if (this.isDragging) {
          const formattedDate = this.formatDate(this.currentYear, this.currentMonth, date);
          this.endDate = formattedDate;
          this.draggingDates = this.getDatesInRange(this.startDate, this.endDate);
        }
      },
      closeModal() {
        this.isModalOpen = false;
        this.selectedDate = null;
        this.newFee = 0;
        this.startDate = '';
        this.endDate = '';
        this.draggingDates = [];
      },
      updateFee() {
        const confirmed = confirm(`요금 변경사항을 저장하시겠습니까?\n${this.startDate} ~ ${this.endDate} : ${this.newFee}원`);

        if (confirmed) {
          const payload = {
            fee: parseInt(this.newFee),
            startDate: this.startDate,
            endDate: this.endDate
          };
          alert
          this.$emit('updateFee', payload);
          this.closeModal();
        }else{
        console.log('cancelled');
      }
      },
      getDatesInRange(start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const dates = [];
        while (startDate <= endDate) {
          dates.push(this.formatDate(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
          startDate.setDate(startDate.getDate() + 1);
        }
        return dates;
      }
    }
  };
</script>
  
<style scoped>
  #calendar {
    width: 100%;
    text-align: center;
    border: 5px solid var(--admin-main-color);
    border-radius: 2rem;
    overflow: hidden;
  }
  
  .calendar-header {
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: 1rem;
    background-color: var(--admin-main-color);
  }
  
  .calendar-header button{
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  .calendar-header button:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  button img {
    width: 20px;
  }

  .calendar-header span {
    font-weight: bold;
    font-size: 2rem;
    color: var(--admin-button-color);
    margin: 0 1rem;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0px;
  }
  
  .dayofweek {
    padding: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .day {
    display: flex;
    flex-direction: column;
    height: 7rem;
  }

  .date {
    text-align: start;
    padding: .5rem;
    font-size: 1.2rem;
  }

  .date-fee {
    padding: .5rem;
    font-weight: bold;
    font-size: 1.2rem;
    border-radius: 5rem;
  }

  .sunday {
    color: red;
  }

  .saturday {
    color: blue;
  }

  .today {
    background-color: #e4e7ff;
  }

  .dragging-start .date  {
    border-top-left-radius: 2rem; 
    border-bottom-left-radius: 2rem; 
  }

  .dragging-end .date {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem; 
  }

  .dragging-middle .date{
    border-radius: 0;
  }

  .dragging .date {
    background-color: var(--admin-button-color); 
    color: #fff; 
    padding: 0.5rem;
  }

  ::selection {
    background: transparent;
  }

  .modal {
    display: block; 
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4); 
    padding-top: 60px;
  }

  .modal-content {
    background-color: #fefefe;
    margin: 5% auto; 
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

  .date-range {
    display: flex;
    flex-direction: row;
    align-items: first baseline;
    margin: 1rem 0;
  }

  .modal-content input {
    padding: .5rem;
    border-radius: .5rem;
    border: 1px solid #d8d7d7;
  }

  .modal-content label {
    font-size: 1.1rem;
    padding: 0rem 2rem;
    width: 80px;
    text-align: center;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

</style>
  