<template>
  <div class="login-page">
    <div class="login-container">
        <img src="@/img/logo.svg" alt="Logo"/>
      <form @submit.prevent="handleLogin">
        <div class="input-group">
          <input type="text" v-model="userid" placeholder="ID" required />
        </div>
        <div class="input-group">
          <input type="password" v-model="password" placeholder="Password" required />
        </div>
        <div v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>
        <button type="submit" class="login-button">LOGIN</button>
      </form>
    </div>
  </div>
</template>
  
<script>
import {requestLogin} from '@/api/dto/requestLogin';
import { getProfile, login } from '@/api/api';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LoginPage',
  data() {
    return {
        userid: '',
      password: '',
      data: [],
    };
  },
  computed:{
    ...mapGetters(['errorMessage']),
  },
  watch: {
    errorMessage(newMessage) {
      this.localErrorMessage = newMessage;
    }
  },
  methods: {
    ...mapActions(['clearError']),
    async handleLogin() {
      const credentials = new requestLogin(this.userid, this.password);
      try {
        await login(credentials);
        await getProfile();
        this.$router.push('/admin/sales-statistics'); // 로그인 성공 후 settings 페이지로 이동
      } catch (error) {
        console.error('Error during login or fetching data:', error);
      }
    },
  },
  created() {
    this.clearError(); // 컴포넌트가 로드될 때 에러 메시지 초기화
  }

};
</script>
  
<style>
  .login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--admin-main-color);
  }
  
.login-container {
  padding: 40px;
  border-radius: 10px;
  text-align: center;
}

.login-container img{
    margin-bottom: 30px;
}
.input-group input {
  width: 100%;
  padding: 10px 15px;
  margin: 5px 0px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.input-group input:focus {
  border-color: #aaa;
  outline: none;
}

.login-button {
  width: 100%;
  padding: 15px;
  background-color: var(--admin-button-color);
  border: none;
  margin-top: 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.error-message {
  color: red;
  margin-top: 10px;
}
</style>
  