// src/api/api.js
import axios from 'axios';
import store from '../../store';
import router from '@/router/index'; // router 모듈 가져오기

import { parkingReservationDTO, busReservationDTO } from './dto/reservationDTO.js';
import { parkingListDTO, parkingLotDTO, parkingFeeDTO, surchargeDTO } from './dto/parkingLotDTO.js';
import { parkingBanDTO } from './dto/parkingBanDTO';
import { driverDTO } from './dto/driverDTO';
import { customerDTO } from './dto/customerDTO';
import { managerDTO } from './dto/managerDTO';
import { getErrorMessage } from '@/utils/errorMessages';
import { airportDTO, stationDTO } from './dto/airportDTO';


// Axios 인스턴스 생성
export const apiClient = axios.create({
  // baseURL: '/dev-api',
  baseURL: 'https://www.stationhere.co.kr/api/',
  headers: {
    'Content-Type': 'application/json',
  },
});

const noAuthPaths = ['managers/login', 'managers/reissue'];
// Axios 요청 인터셉터 추가
apiClient.interceptors.request.use(config => {
  const token = store.getters.loginToken;

  // 특정 경로에 대해 인터셉터를 적용하지 않도록 조건 추가
  if (!noAuthPaths.includes(config.url)) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

export const handleApiError = (error) => {
  const errorMessage = getErrorMessage(error);
  store.dispatch('setError', errorMessage);
  throw error;
};

// Axios 응답 인터셉터 추가
apiClient.interceptors.response.use(response => {

  return response;
  }, 
  async error => {
    const originalRequest = error.config;

    // 토큰 만료 시
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

    try {
      const newToken = await refreshAuthToken();
      originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
      return apiClient(originalRequest);
    } catch (error) {
      console.error('Error during token refresh:', error);
      // 필요 시 로그아웃 처리 추가
      return Promise.reject(error);
    }
  }

  return Promise.reject(error);
});


// 관리자 로그인
export const login = async (credentials) => {
  try {
    const response = await apiClient.post('managers/login', credentials);
    const { accessToken, refreshToken } = response.data;

    // 토큰과 리프레시 토큰을 저장
    store.dispatch('updateLoginToken', accessToken);
    store.dispatch('updateRefreshToken', refreshToken);

    return accessToken;
  } catch (error) {
    handleApiError(error);
  }
};

// 관리자 정보 요청
export const getProfile = async () => {
  try {
    const response = await apiClient.get('managers/info');
    const userInfo = response.data;

    store.dispatch('updateUserInfo', userInfo); // 사용자 정보 저장

    return response.data;
  } catch (error) {
    handleApiError(error);

  }
};

// 관리자 로그아웃
export const logout = async () => {
  try {
    const response = await apiClient.delete('managers/logout');
    store.dispatch('removeTokens'); // 로그아웃 성공 후 토큰 삭제
    store.dispatch('parkingLot/removeselectParkingLot');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 토큰 만료 시 새로운 토큰을 가져오는 함수
const refreshAuthToken = async () => {
  try {
    const refreshToken = store.getters.refreshToken;
    const response = await axios.put( 
      'https://www.stationhere.co.kr/api/managers/reissue',      
       {}, // PUT 요청의 경우 데이터가 없으면 빈 객체 전달
       {
        headers: {
          'RefreshToken': `Bearer ${refreshToken}`
        }
      }
    );
    // 상태 코드가 200이 아닌 경우 명시적으로 에러를 발생시킴
    if (response.status !== 200) {
      router.push({ name: 'admin' });
    }
    const newToken = response.data.accessToken;
    const newRefreshToken = response.data.refreshToken;

    // 새로운 토큰과 리프레시 토큰을 저장
    store.dispatch('updateLoginToken', newToken);
    store.dispatch('updateRefreshToken', newRefreshToken);

    return newToken;
  } catch (error) {
    console.error('Error refreshing auth token:', error);
    router.push({ name: 'admin' });
    throw error;
  }
};

//매출통계 요청 함수
export const getSales = async (parkinglotId, startDay, endDay) => {
  const response = await apiClient.get(`/parking-areas/${parkinglotId}/sales`, {
    params: {
      startDate: startDay,
      endDate: endDay
    }}
  );
  return response.data;
};

// 주차장 데이터 요청 함수
export const getParkingLots = async () => {
  try {
    const response = await apiClient.get('/parking-areas');
    if (response.data && response.data.list) {
      return response.data.list.map(parkingLot => new parkingLotDTO(parkingLot));
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.error('Error fetching data:', error.message || error);
    throw error;
  }
};

// 전체 주차장 데이터 요청 함수
export const getParkingLotsAll = async () => {
  try {
    const response = await apiClient.get('/parking-areas/all');
    if (response.data && response.data.list) {
      return response.data.list.map(parkingLot => new parkingLotDTO(parkingLot));
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.error('Error fetching data:', error.message || error);
    throw error;
  }
};

// 주차장 데이터 수정 함수
export const modifyParkingLot = async (parkinglotID, data) => {
  try {
    const response = await apiClient.put(`/parking-areas/${parkinglotID}`, data);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

// 주차장 데이터 추가 함수
export const postParkingLots = async (data) => {
  try {
    const response = await apiClient.post('/parking-areas', data);
    return response;
  } catch (error) {
    handleApiError(error);

  }
};

// 주차장 데이터 삭제 함수
export const deleteParkingLots = async (parkinglotID) => {
  try {
    const response = await apiClient.delete(`/parking-areas/${parkinglotID}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 주차장 리스트 요청 함수
export const getParkingList = async () => {
  try {
    const response = await apiClient.get('/parking-areas/pair');
    return response.data.list.map(parkingLot => new parkingListDTO(parkingLot));
  } catch (error) {
    handleApiError(error);
  }
};

// 주차장 예약 데이터 요청 함수
export const getParkingReserv = async (parkingLotId, filter, start, end) => {
  try {
    const response = await apiClient.get(`/parking-areas/${parkingLotId}/reservations?filter=${filter}&startDate=${start}&endDate=${end}`);
    return response.data.list.map(reservation => new parkingReservationDTO(reservation));
  } catch (error) {
    handleApiError(error);
  }
};

// 입출차 데이터 요청 함수
export const getEntryExit = async (parkingLotId, date) => {
  try {
    const response = await apiClient.get(`/parking-areas/${parkingLotId}/reservations/both-time?targetDate=${date}`);
    return response.data.list.map(reservation => new parkingReservationDTO(reservation));
  } catch (error) {
    handleApiError(error);
  }
};

// 버스 예약 데이터 요청 함수
export const getBusReserv = async (parkingLotId, filter, start, end) => {
  try {
    const response = await apiClient.get(`/parking-areas/${parkingLotId}/reservations/bus?filterBy=${filter}&startDate=${start}&endDate=${end}`);
    return response.data.list.map(reservation => new busReservationDTO(reservation));
  } catch (error) {
    handleApiError(error);
  }
};


// 예약거절 데이터 요청 함수
export const getParkingBan = async (parkingLotId) => {
  try {
    const response = await apiClient.get(`/parking-areas/${parkingLotId}/ban`);
    return response.data.list.map(response => new parkingBanDTO(response));
  } catch (error) {
    handleApiError(error);
  }
};

// 예약거절 데이터 추가 함수
export const postParkingBan = async (parkingLotId, data) => {
  try {
    const response = await apiClient.post(`/parking-areas/${parkingLotId}/ban`, data);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

// 예약거절 데이터 삭제 함수
export const deleteParkingBan = async (parkingLotId, dataId) => {
  try {
    const response = await apiClient.delete(`/parking-areas/${parkingLotId}/ban/${dataId}`);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

// 주차장 요금데이터 요청 함수
export const getParkingFees = async (parkingLotId) => {
  try {
    const response = await apiClient.get(`/parking-areas/${parkingLotId}/fees`);
    return response.data.list.map(response => new parkingFeeDTO(response));
  } catch (error) {
    handleApiError(error);
  }
};

// Fee 데이터를 전송하는 함수
export const putParkingFees = async (parkingLotId, feeList) => {
  try {
    const response = await apiClient.post(`parking-areas/${parkingLotId}/fees/batch`, feeList);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fee default를 전송하는 함수
export const patchParkingFeeDefault = async (parkingLotId, defaultCharge) => {
  try {
    const response = await apiClient.patch(`parking-areas/${parkingLotId}/fees/default`, defaultCharge);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 할인할증 요금을 받아오는 함수
export const getSurcharge = async (parkingLotId) => {
  try {
    const response = await apiClient.get(`parking-areas/${parkingLotId}/fees/surcharge`);
    return response.data.list.map(response => new surchargeDTO(response));
  } catch (error) {
    handleApiError(error);
  }
};
// 할인할증 요금을 추가하는 함수
export const postSurcharge = async (parkingLotId, surcharge) => {
  try {
    const response = await apiClient.post(`parking-areas/${parkingLotId}/fees/surcharge`, surcharge);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 할인할증 요금을 수정하는 함수
export const putSurcharge = async (parkingLotId, surcharge, surchargeId) => {
  try {
    const response = await apiClient.put(`parking-areas/${parkingLotId}/fees/surcharge/${surchargeId}`, surcharge);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
// 할인할증 요금을 삭제하는 함수
export const delSurcharge = async (parkingLotId, surchargeId) => {
  try {
    const response = await apiClient.delete(`parking-areas/${parkingLotId}/fees/surcharge/${surchargeId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};


// 기사 데이터 요청 함수
export const getDrivers = async () => {
  try {
    const response = await apiClient.get('/drivers');
    return response.data.list.map(drivers => new driverDTO(drivers));
  } catch (error) {
    handleApiError(error);
  }
};

// 기사 데이터 추가 함수
export const postDrivers = async (data) => {
  try {
    const response = await apiClient.post('/drivers', data);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

// 기사 데이터 삭제 함수
export const deleteDrivers = async (driverID) => {
  try {
    const response = await apiClient.delete(`/drivers/${driverID}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 회원 데이터 요청 함수
export const getCustomers = async () => {
  try {
    const response = await apiClient.get('/customers');
    return response.data.list.map(customers => new customerDTO(customers));
  } catch (error) {
    handleApiError(error);
  }
};

// 관리자 데이터 요청 함수
export const getManagers = async () => {
  try {
    const response = await apiClient.get('/managers');
    return response.data.list.map(managers => new managerDTO(managers));
  } catch (error) {
    handleApiError(error);
  }
};

// 관리자 데이터 추가 함수
export const postManagers = async (data) => {
  try {
    const response = await apiClient.post('/managers', data);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

// 관리자 데이터 삭제 함수
export const deleteManager = async (managerId) => {
  try {
    const response = await apiClient.delete(`/managers/${managerId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 공항 데이터 요청 함수
export const getAirPort = async () => {
  try {
    const response = await apiClient.get('/airports/all');
    return response.data.list.map(datas => new airportDTO(datas));
  } catch (error) {
    handleApiError(error);
  }
};

// 공항 데이터 추가 함수
export const postAirport = async (data) => {
  try {
    const response = await apiClient.post('/airports', data);
    return response;
  } catch (error) {
    handleApiError(error);

  }
};

// 공항 데이터 삭제 함수
export const deleteAirport = async (id) => {
  try {
    const response = await apiClient.delete(`/airports/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 공항 데이터 수정 함수
export const modifyAirport = async (id, data) => {
  try {
    const response = await apiClient.put(`/airports/${id}`, data);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};


// 정류장 데이터 요청 함수
export const getStation = async () => {
  try {
    const response = await apiClient.get('/stations');
    return response.data.list.map(datas => new stationDTO(datas));
  } catch (error) {
    handleApiError(error);
  }
};

// 공항 데이터 추가 함수
export const postStation = async (data) => {
  try {
    const response = await apiClient.post('/stations', data);
    return response;
  } catch (error) {
    handleApiError(error);

  }
};

// 공항 데이터 삭제 함수
export const deleteStation = async (id) => {
  try {
    const response = await apiClient.delete(`/stations/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 공항 데이터 수정 함수
export const modifyStation = async (id, data) => {
  try {
    const response = await apiClient.put(`/stations/${id}`, data);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

// Axios 인스턴스 생성
const addrClient = axios.create({
  baseURL: 'https://sgisapi.kostat.go.kr/OpenAPI3/',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchToken = async () => {
  try {
    console.log(process.env.OPEN_API_KEY, process.env.OPEN_API_SECRET)
    const response = await addrClient.get('/auth/authentication.json', {
      params: {
        consumer_key: process.env.VUE_APP_OPEN_API_KEY,
        consumer_secret: process.env.VUE_APP_OPEN_API_SECRET
      }
    });

    const token = response.data.result.accessToken;
    store.dispatch('updateAccessToken', token);

    return token;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

export const getSido = async () => {
  try {
    const accessToken = store.getters.accessToken;

    if (!accessToken) {
      throw new Error('Access token is missing');
    }
    const response = await addrClient.get('/addr/stage.json', {
      params: {
        accessToken: accessToken,
      }
    });

    return response.data.result.map(feature => ({
      code: feature.cd,
      name: feature.full_addr
    }));
  } catch (error) {
    console.error('Error fetching sido:', error);
    throw error;
  }
}

export const getSigungu = async (sidoCode) => {
  try {
    const response = await addrClient.get('/addr/stage.json', {
      params: {
        accessToken: store.getters.accessToken,
        cd: `${sidoCode}`
      }
    });

    return response.data.result.map(feature => ({
      code: feature.cd,
      name: feature.addr_name
    }));
  } catch (error) {
    console.error('Error fetching sido:', error);
    throw error;
  }
}

export const getEupmyundong = async (sigunguCode) => {
  try {
    const response = await addrClient.get('/addr/stage.json', {
      params: {
        accessToken: store.getters.accessToken,
        cd: `${sigunguCode}`
      }
    });

    return response.data.result.map(feature => ({
      code: feature.cd,
      name: feature.addr_name
    }));
  } catch (error) {
    console.error('Error fetching sido:', error);
    throw error;
  }
}

export const getGeocoding = async (address) => {
  try {
    const response = await addrClient.get('/addr/geocodewgs84.json', {
      params: {
        accessToken: store.getters.accessToken,
        address: `${address}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching sido:', error);
    return error;
  }
}

// 결제 취소 정보 요청 함수
export const getCancellationInfo = async (parkingAreaReservationId) => {
  try {
    const response = await apiClient.get(`/parking-areas/reservations/${parkingAreaReservationId}/cancellation-info`);
    return response.data;
  } catch (error) {
    console.error('Error fetching cancellation info:', error);
    throw error;
  }
};