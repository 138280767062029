import { formatTime, formatPhoneNumber } from '@/utils/dataFormatUtils';

export class inquiriesDTO {
    constructor(data) {
      this.id = data.id;
      this.customerId = data.customerId;
      this.customerPhone = data.customerPhone;
      this.title = data.title;
      this.message = data.message;
      this.status = data.status;
      this.createdAt = data.createdAt;
      this.updatedAt = data.updatedAt;
      this.response = data.response;
    }

  get displayData() {
    return {
      "순번": this.id,
      "분류":this.title,
      "작성자": formatPhoneNumber(this.customerPhone),
      "작성날짜": formatTime(this.createdAt),
      "문의내역": this.message,
      "답변여부": this.statusCheck(this.status),
      "답변내용":this.response,
    };
  }

  statusCheck(eng) {
    const EngMapping = {
      "COMPLETED": "답변 완료",
      "WAITING": "답변 대기"
    };
    return EngMapping[eng] || eng; // 매핑이 없는 경우 원래 값을 반환
  }
} 
  