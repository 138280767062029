<template>
  <div>
    <h2 class="admin-title">기사 관리</h2>
    <div class="content-container">
      <ManageTable 
        :APIDatas="APIDatas" 
        :columns="columns" 
        class="table-container"
        @delete-item="deleteItem"
        :showDeleteColumn="showDeleteColumn"
        style="width: 70%"
        />
      <FormComponent
        :fields="formFields"
        submitButtonText="기사 추가"
        @submit="handleFormSubmit"
        class="form-container"
        style="width: 30%;"
        />
    </div>
  </div>
</template>

  
<script>
import ManageTable from '@/components/ManageTable.vue';
import FormComponent from '@/components/FormComponent.vue';
import { getDrivers, postDrivers, deleteDrivers, getParkingLots, getAirPort } from '@/api/api';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ManageTable,
    FormComponent
  },
  
  data() {
    return {
      columns: ["기사 연락처", "차량번호","운행공항", "운행상태", "좌석수"],
      APIDatas: [],
      formFields: [
        { name: 'driverphone', label: '기사 연락처', type: 'text', placeholder: '000-0000-0000' },
        { name: 'carnum', label: '차량번호', type: 'text', placeholder: '00가 0000' },
        { name: 'seats', label: '좌석수', type: 'text', placeholder: '숫자만 기입해주세요' },
        { name: 'airportId', label: '운행공항', type: 'select', placeholder: '공항을 선택해주세요', selectList: []},
      ]
    };
  },
  computed: {
    ...mapGetters('airport', ['airportList', 'getIdNamePairs']),
  },
  methods: {
    ...mapActions('airport', ['setairportlist']),
    async fetchAirport() {
      const data = await getAirPort();
      await this.setairportlist(data); 
    },
    async fetchDriversData() {
      try {
        const APIDatas = await getDrivers();
        this.APIDatas = APIDatas.map(data => data.displayData);
      } catch (error) {
        console.error('Error fetching driverdatas:', error);
      }
    },
    
    async fetchParkingAreas() {
      try {
        const parkingAreas = await getParkingLots();
        const selectList = parkingAreas.map(area => ({
          value: area.id,
          text: area.name
        }));
        this.updateParkingAreaSelectList(selectList);
      } catch (error) {
        console.error('Error fetching parking areas:', error);
      }
    },

    updateParkingAreaSelectList(selectList) {
      const parkingAreaField = this.formFields.find(field => field.name === 'parkingAreaId');
      if (parkingAreaField) {
        parkingAreaField.selectList = selectList;
      }
    },

    async handleFormSubmit(formData) {
      if (formData instanceof SubmitEvent) {
        console.warn('Invalid form data:', formData);
        return;
      }     
      
      console.log('formsubmit',formData)
      const dataToSend = {
        driverPhone: formData.driverphone,
        number: formData.carnum,
        seat: parseInt(formData.seats),
        airportId: formData.airportId
      };

      // API 통신을 한 번만 수행
      try {
        await postDrivers(dataToSend);
        // 업데이트된 데이터를 다시 가져옵니다.
        this.fetchDriversData();
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },

    async deleteItem(index) {
      const driverID = this.APIDatas[index].No;
      try {
        await deleteDrivers(driverID);
        this.APIDatas.splice(index, 1);
      } catch (error) {
        console.error('Error deleting reservation:', error);
        // 추가적인 에러 처리 로직이 필요하면 여기서 작성하세요.
      }
    },
  },
  created() {
    this.fetchAirport();
    this.fetchDriversData();
    this.fetchParkingAreas(); // 주차장 리스트를 가져오는 메서드 호출

  },
  mounted() {
    // 컴포넌트가 마운트될 때 selectList를 업데이트
    this.formFields.find(field => field.name === 'airportId').selectList = this.getIdNamePairs;
  }
};
</script>

