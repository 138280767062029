<template>
  <div id="payment-page">
    <div v-if="isLoading">결제 창을 불러오는 중...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <p>결제 진행 중입니다. 잠시만 기다려주세요.</p>
      <p v-if="amount">결제 금액: {{ amount }}원</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  setup() {
    const isLoading = ref(true);
    const error = ref(null);
    const amount = ref(null);

    const loadPaypleScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://democpay.payple.kr/js/v1/payment.js';
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    const initiatePayment = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const orderId = urlParams.get('orderId') || `ORDER_${Date.now()}`;
      const payAmount = urlParams.get('amount');

      if (!payAmount) {
        error.value = '결제 금액이 지정되지 않았습니다.';
        return;
      }

      amount.value = parseInt(payAmount, 10);

      const paymentInfo = {
        PCD_PAY_TYPE: 'card',
        PCD_PAY_WORK: 'PAY',
        PCD_CARD_VER: '02', // 앱카드 결제 설정
        PCD_PAY_GOODS: '테스트 상품',
        PCD_PAY_TOTAL: amount.value,
        PCD_PAY_OID: orderId,
        PCD_PAYER_NO: '1234',
        PCD_PAYER_HP: '01012345678',
        PCD_RST_URL: 'https://your-server.com/payment-result',
        PCD_MODAL_RETURN_URL: window.location.href,
        PCD_AUTOCLOSE: 'Y',
        clientKey: 'test_DF55F29DA654A8CBC0F0A9DD4B556486'
      };

      if (typeof window.PaypleCpayAuthCheck === 'function') {
        window.PaypleCpayAuthCheck(paymentInfo, function(response) {
          if (response.PCD_PAY_RST === 'success') {
            console.log('결제 성공:', response);
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'PAYMENT_SUCCESS',
                data: response
              }));
            }
          } else {
            console.error('결제 실패:', response);
            error.value = '결제에 실패했습니다.';
          }
        });
      } else {
        error.value = '페이플 결제 모듈을 불러오는 데 실패했습니다.';
      }
    };

    onMounted(async () => {
      try {
        await loadPaypleScript();
        isLoading.value = false;
        initiatePayment();
      } catch (err) {
        console.error('스크립트 로딩 중 오류 발생:', err);
        error.value = '결제 모듈을 불러오는 데 실패했습니다.';
        isLoading.value = false;
      }
    });

    return {
      isLoading,
      error,
      amount
    };
  }
};
</script>

<style scoped>
#payment-page {
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0 auto;
  text-align: center;
}
</style>