// src/config/menuConfig.js
export const menuConfig = {
    '최고관리자': [
      { name: '매출 통계', icon: 'ic_sales.svg', path: '/admin/sales-statistics' },
      { title: '주차장', items: [
        { name: '주차장 예약리스트', icon: 'ic_reservation.svg', path: '/admin/parking-reservation' },
        { name: '입출차 리스트', icon: 'ic_entryexit.svg', path: '/admin/car-entry-exit' },
        { name: '주차장 관리', icon: 'ic_parkinglot.svg', path: '/admin/settings-parkinglot' },
        { name: '요금설정', icon: 'ic_parkingfee.svg', path: '/admin/settings-fee' },
        // { name: '특정일 예약거절', icon: 'ic_parkingban.svg', path: '/admin/settings-ban' }
      ]},
      { title: '수요응답버스', items: [
        { name: '수요응답버스 예약리스트', icon: 'ic_bus.svg', path: '/admin/bus-reservation' },
        { name: '기사 관리', icon: 'ic_driver.svg', path: '/admin/settings-driver' },
      ]},
      { title: ' ', items: [
        { name: '문의내역', icon: 'ic_inquiry.svg', path: '/admin/inquiries' },
      ]},
      { title: '환경설정', items: [
        { name: '공항관리', icon: 'ic_parkinglot.svg', path: '/admin/airports'},
        { name: '정류장관리', icon: 'ic_parkinglot.svg', path: '/admin/stations'},
        { name: '회원관리', icon: 'ic_user.svg', path: '/admin/settings-member'},
        { name: '권한관리', icon: 'ic_manager.svg', path: '/admin/settings-auth'}
      ]}
    ],
    '일반관리자': [
      { name: '매출 통계', icon: 'ic_sales.svg', path: '/admin/sales-statistics' },
      { title: '주차장', items: [
        { name: '주차장 예약리스트', icon: 'ic_reservation.svg', path: '/admin/parking-reservation' },
        { name: '입출차 리스트', icon: 'ic_entryexit.svg', path: '/admin/car-entry-exit' },
        { name: '주차장 관리', icon: 'ic_parkinglot.svg', path: '/admin/settings-parkinglot' },
        { name: '요금설정', icon: 'ic_parkingfee.svg', path: '/admin/settings-fee' },
        // { name: '특정일 예약거절', icon: 'ic_parkingban.svg', path: '/admin/settings-ban' }
      ]},
      { title: '수요응답버스', items: [
        { name: '수요응답버스 예약리스트', icon: 'ic_bus.svg', path: '/admin/bus-reservation' },
      ]},
      { title: ' ', items: [
        { name: '문의내역', icon: 'ic_inquiry.svg', path: '/admin/inquiries' },
      ]},
    ],
    '운행사': [
      { name: '매출 통계', icon: 'ic_sales.svg', path: '/admin/sales-statistics' },
      { title: '수요응답버스', items: [
        { name: '전체주차장 리스트', icon: 'ic_parkinglot.svg', path: '/admin/parkinglots' },
        { name: '수요응답버스 예약리스트', icon: 'ic_bus.svg', path: '/admin/bus-reservation' },
        { name: '기사 관리', icon: 'ic_driver.svg', path: '/admin/settings-driver' },
      ]},
      { title: '주차장', items: [
        { name: '주차장 예약리스트', icon: 'ic_reservation.svg', path: '/admin/parking-reservation' },
        { name: '입출차 리스트', icon: 'ic_entryexit.svg', path: '/admin/car-entry-exit' },
        { name: '주차장 관리', icon: 'ic_parkinglot.svg', path: '/admin/settings-parkinglot' },
        { name: '요금설정', icon: 'ic_parkingfee.svg', path: '/admin/settings-fee' },
        { name: '특정일 예약거절', icon: 'ic_parkingban.svg', path: '/admin/settings-ban' }
      ]},
    ],
  };
  