<template>
  <header class="header">
    <div class="logo-container">
      <img src="@/img/logo.svg" alt="Logo" />
    </div>
    <div class="profile" ref='profile' @click="toggleDropdown">
      <img src="@/img/profile.svg" alt="Profile" />      
      <span>{{ userInfo.name }} 님</span>
      <div class="profile-dropdown" v-if="isDropdownOpen">
        <div class="dropdown-content">
          <div class="profile-info">
            <span>{{ userInfo.role }}</span>
          </div>
          <div class="profile-actions">
            <button @click="logout">로그아웃</button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { logout } from '@/api/api.js'; 

export default {
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    ...mapActions(['removeTokens', 'removeUserInfo', 'clearError']),
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async logout() {
      try {
        await logout();
        this.removeTokens();
        this.removeUserInfo();
        this.clearError();
        this.$router.push('/admin');
      } catch (error) {
        console.error('Logout failed:', error);
        alert(`로그아웃 중 서버에러가 발생했습니다. 잠시후 다시 시도해주세요.`);
      }
    },
    handleClickOutside(event) {
      if (this.$refs.profile && !this.$refs.profile.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>


<style>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px 20px 0px;
  background-color: var(--admin-main-color);
}

.logo-container {
  display: flex;
  align-items: center;
  position: relative;
}

.logo-container img {
  height: 50px; /* 로고 이미지의 크기를 조정 */
  margin: 0 20px; /* 마진 설정 */
}

.profile {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.profile img {
  width: 40px;
  height: 40px;
  margin: 0px 10px;
  border-radius: 50%; /* 프로필 이미지 둥글게 만들기 */
}

.profile span {
  margin-right: 10px;
  font-size: 18px;
}

.profile-dropdown {
  position: absolute;
  top: 60px; /* 프로필 컨테이너 바로 아래에 위치 */
  right: 0;
  overflow: visible;
  width: 250px; /* 드롭다운 메뉴 너비 설정 */
}

.dropdown-content {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none; /* 마우스 이벤트 무시 */
}

.profile-actions {
  width: 100%;
  border-top: 1px solid var(--admin-main-color);
  padding-top: 10px;
}
.profile-actions button {
  width: 100%;
  background: none;
  cursor: pointer;
  padding: 10px 0px;
}

.profile-actions button:hover {
    background-color: var(--admin-main-color);
    border-radius: 10px;
}
</style>
