import { formatPhoneNumber } from "@/utils/dataFormatUtils";

class ParkingArea {
  constructor({ id, name }) {
    this.id = id;
    this.name = name;
  }
}

class ParkingAreaList {
  constructor(list = []) {
    this.list = list.map(item => new ParkingArea(item));
  }

  get displayList() {
    return this.list.map(item => item.name).join(', '); 
  }
}

export class driverDTO {
  constructor({ airportInfo, id, driverPhone, number, state, seat, parkingAreaList}) {
    this.airportInfo = airportInfo
    this.id = id;
    this.driverPhone = formatPhoneNumber(driverPhone);
    this.number = number;
    this.state = state;
    this.seat = seat;
    this.parkingAreaList =  new ParkingAreaList(parkingAreaList);

  }

  get displayData() {
    return {
      "No": this.id,
      "기사 연락처": this.driverPhone,
      "차량번호": this.number,
      "운행상태": this.stateTokorean(this.state),
      "좌석수": this.seat,
      "운행공항": this.airportInfo.name
    };
  }

  stateTokorean(org) {
    const Eng = {  
      "false": "운행불가",
      "true": "운행가능"
    };
    return Eng[org] || org; // 매핑이 없는 경우 원래 값을 반환
  }
}

