<template>
  <div class="admin-page">
    <h2 class="admin-title">요금 설정</h2>
    <div v-if="loading"></div>
    <div v-else class="admin-page">
      <div v-if="parkingLots.length > 0"> 
        <ParkingDropdown :items="parkingLots" :defaultText="selectedParkingLot.name" @change="selectParkingLot"/>
        <div class="content-container">
        <CalendarComponent 
          v-if="selectedParkingLot"
          :fees="fees" 
          @updateFee="handleUpdateFee"
          class="table-container"
          style="width: 65%"/>
        <EditFeeTable
          v-if="surcharge"
          :surchargeList=surcharge
          :originFee=selectedParkingLot.defaultCharge
          @add-surcharge="handleAdd"
          @delete-surcharge="handleDelete"
          @update-default-fee="handleDefaultFee"
          style="width: 35%"/>
        </div>
      </div>
      <div v-else class="empty-container">
        <EmptyParkinglot/>
      </div>
    </div>
  </div>      
</template>

  
<script>
import CalendarComponent from '@/components/CalendarComponent.vue';
import ParkingDropdown from '@/components/ParkingDropdown.vue';
import EditFeeTable from '@/components/EditFeeTable.vue';
import EmptyParkinglot from '@/components/EmptyParkinglot.vue'
import { getParkingList, getParkingFees, putParkingFees, getSurcharge, delSurcharge, postSurcharge, patchParkingFeeDefault } from '@/api/api';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    CalendarComponent,
    ParkingDropdown,
    EditFeeTable,
    EmptyParkinglot
  },
  data() {
    return {
      loading: true,
      parkingLots: [],
      fees: [],
      surcharge: [],
      formFields: [
      { name: 'startdate', label: '시작일', type: 'date', placeholder: '' },
      { name: 'starttime', label: '시작시간', type: 'time', placeholder: '' },
      { name: 'enddate', label: '종료일', type: 'date', placeholder: '' },
      { name: 'endtime', label: '종료시간', type: 'time', placeholder: '' }
      ],
    };
  },
  computed: {
    ...mapGetters('parkingLot', ['selectedParkingLot'])
  },
  created() {
    this.fetchParkingList();
  },
  methods: {
    ...mapActions('parkingLot', ['setselectParkingLot']),

    async fetchParkingList() {
      try {
        const parkingLots = await getParkingList();
        this.parkingLots = parkingLots;
        console.log(this.selectedParkingLot, "선택됨")

        if (!this.selectedParkingLot) {
          await this.setselectParkingLot(this.parkingLots[0]); 
          console.log('==>', this.selectedParkingLot, this.parkingLots[0]);
        }
        console.log("result",this.selectedParkingLot)
        this.fetchParkingFeeData(this.selectedParkingLot.id); 
        this.fetchSurchargeFee(this.selectedParkingLot.id);
      } catch (error) {
        console.error(error);
      } finally {
          this.loading = false;
      }
    },
    async fetchParkingFeeData(parkingLotId) {
      if (parkingLotId === undefined) {
        console.warn('Invalid parking lot ID');
        return;
      }
      try {
        const response = await getParkingFees(parkingLotId);
        this.fees = response;
      } catch (error) {
        console.error('Error fetching parking fees:', error);
      }
    },
    selectParkingLot(parkingLot) {
      // Vuex 스토어에 selectedParkingLot 저장
      this.setselectParkingLot(parkingLot);
      console.log("selectPArkingLot - method",this.selectedParkingLot)
      this.fetchParkingFeeData(this.selectedParkingLot.id); 
      this.fetchSurchargeFee(this.selectedParkingLot.id);
    },
    async handleUpdateFee(newFee) {
      try{
        await putParkingFees(this.selectedParkingLot.id, newFee);
        alert(`요금이 성공적으로 등록되었습니다.`);
        this.fetchParkingFeeData(this.selectedParkingLot.id);
      } catch (error) {
        alert(`요금등록에 실패했습니다. 다시 시도해주세요.`);
      } 
    },
    async fetchSurchargeFee(parkingLotId) {
      if (parkingLotId === undefined) {
        console.warn('Invalid parking lot ID');
        return;
      }
      try {
        const response = await getSurcharge(parkingLotId);
        this.surcharge = response;
      } catch (error) {
        console.error('Error fetching surcharge:', error);
      }
    },
    async handleDefaultFee(defaultFee) {
      const confirmed = confirm(`기본 요금을 ${defaultFee.defaultCharge}원으로 변경하시겠습니까?\n특정일 요금설정값이 초기화 됩니다.`);
      if (confirmed) {
        await patchParkingFeeDefault(this.selectedParkingLot.id, defaultFee);
        alert('기본 요금값을 변경하였습니다.');
        this.fetchParkingFeeData(this.selectedParkingLot.id)
      }
    },
    async handleDelete(deleteId) {
      const confirmed = confirm(`해당 할인/할증 설정을 삭제하시겠습니까?`);

      if (confirmed) {
        try{
          await delSurcharge(this.selectedParkingLot.id, deleteId);
          alert('할인/할증 설정이 삭제되었습니다.');
          this.fetchSurchargeFee(this.selectedParkingLot.id)
        } catch (error) {
          alert('삭제중 에러가 발생하였습니다.\n다시 시도해주세요.');
        }
      } else {
        console.log('cancelled');
      }
    },
    async handleAdd(newsurcharge) {
      const confirmed = confirm(`해당 할인/할증 설정을 추가하시겠습니까?`);

      if (confirmed) {
        try{
          await postSurcharge(this.selectedParkingLot.id, newsurcharge);
          alert('할인/할증 설정이 추가되었습니다.');
          this.fetchSurchargeFee(this.selectedParkingLot.id);
        } catch (error) {
          if (error.response.data.code === "E-006"){
            alert('추가하는 기간과 중복되는 할인/할증 설정이 존재합니다.\n다시 확인해주세요.')
          } else {
            alert('추가중 에러가 발생하였습니다.\n다시 시도해주세요.');
          }
        }
      } else {
        console.log('cancelled');
      }
    },
    async saveChanges(updatedFeeList) {
      const dataToSend = {
        list: updatedFeeList.map(fee => ({
          startDay: fee.startDay,
          endDay: fee.endDay,
          dayFee: fee.dayFee,
          addFee: fee.addFee
        }))
      };
      
      // 확인 팝업 띄우기
      const confirmed = confirm(`요금 변경사항을 저장하시겠습니까?`);

      if (confirmed) {
        try {   
          await putParkingFees(this.selectedParkingLot.id, dataToSend);
          this.fetchParkingFeeData(this.selectedParkingLot.id);
        } catch (error) {
          console.error('Error submitting form:', error);
        } 
      } else{
        console.log('cancelled');

      }
    },
  }
};
</script>
