<template>
    <div class="search-bar">
      <select v-model="selectedField" class="search-select">
        <option disabled value="">::선택::</option>
        <option v-for="field in fields" :key="field.value" :value="field.value">{{ field.label }}</option>
      </select>
      <input
        type="text"
        v-model="query"
        @input="emitInput"
        @keyup.enter="emitSearch"
        placeholder="검색어를 입력하세요"
        class="search-input"
      />
      <button @click="emitSearch" class="search-button">검색</button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: String,
        required: true,
      },
      field: {
        type: String,
        required: true,
      },
      fields: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        query: this.value,
        selectedField: this.field,
      };
    },
    methods: {
      emitInput() {
        this.$emit('input', this.query);
        this.$emit('field-change', this.selectedField);
      },
      emitSearch() {
        if (!this.query) {
          this.$emit('search', { query: '', field: this.selectedField });
        } else if(!this.selectedField) {
            alert("검색필드를 선택해주세요.");
        } else {
        this.$emit('search', { query: this.query, field: this.selectedField });
        }
      },
    },
    watch: {
      value(newVal) {
        this.query = newVal;
      },
      field(newVal) {
        this.selectedField = newVal;
      },
    },
  };
  </script>
  
  <style>
  .search-bar {
    display: flex;
    align-items: center;
  }
  
  .search-select {
    padding: 5px;
    border: 1px solid var(--admin-button-color);
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .search-input {
    flex: 1;
    padding: 5px;
    border: 1px solid var(--admin-button-color);
    border-radius: 4px;
  }

  .search-input:focus {
    outline: none;
    border: 2px solid var(--admin-button-color);
  }

  .search-button {
    background: var(--admin-button-color); 
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25); 
    border-radius: 6px;
    margin: 0px 0px 0px 10px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    color: white;
  }
  </style>
  