<template>
  <div class="reservation-details" @click.stop>
    <button @click="closeDetails" class="close-button">x</button>
    <div class="detail-header">
      <div class="status">
        <img :src="statusIcon" :alt="reservation['예약상태']" class="status-icon" />
        <div class="reservation-info">
          <span class="parking-lot">{{ parkingLotName }}</span>
          <span class="dates">{{ reservation["이용일"] }}</span>
        </div>
      </div>
    </div>
    <hr class="detail-separator" style="margin: 0 0;">
    <div class="content">
      <h3 class="no-margin-padding">예약 정보</h3>
      <div class="info-row">
        <div class="info-col">
          <span class="label">연락처</span>
          <span class="value">{{ reservation["연락처"] }}</span>
        </div>
        <div class="info-col">
          <span class="label">예약 인원</span>
          <span class="value">{{ reservation["예약 인원"] }}</span>
        </div>
      </div>
      <div class="info-row">
        <div class="info-col">
          <span class="label">차량 정보</span>
          <span class="value">{{ reservation["차량 정보"] }}</span>
        </div>
        <div class="info-col">
          <span class="label">항공타입</span>
          <span class="value">{{ reservation["항공타입"] }}</span>
        </div>
      </div>
      <div class="info-row">
        <div class="info-col">
          <span class="label">캐리어 수량</span>
          <span class="value">{{ reservation["캐리어 수량"] }}</span>
        </div>
        <div class="info-col">
          <span class="label">골프백 수량</span>
          <span class="value">{{ reservation["골프백 수량"] }}</span>
        </div>
      </div>
      <div class="info-row">
        <div class="info-col">
          <span class="label">예약 플랫폼</span>
          <span class="value">{{ reservation["예약 플랫폼"] }}</span>
        </div>
        <div class="info-col">
          <span class="label">총 금액</span>
          <span class="value">{{ reservation["총 금액"] }}</span>
        </div>
      </div>
    </div>
    <hr class="detail-separator" style="margin: 0 0;">
    <div class="content">
      <div class="info-row no-margin-padding">
        <div class="info-col">
          <span class="label">입차 시간</span>
          <span class="value">{{ reservation["입차 시간"] }}</span>
        </div>
        <div class='v-line'></div>
        <div class="info-col">
          <span class="label">출차 시간</span>
          <span class="value">{{ reservation["출차 시간"] }}</span>
        </div>
      </div>
      <div class="button-row no-margin-padding" v-if="reservation['예약상태'] === '이용중' || reservation['예약상태'] === '출차완료'">    
        <button class="photo-button" @click="openPhoto">차량 촬영 사진 보기</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    parkingLotName: {
      type: String,
      required: true
    },
    reservation: {
      type: Object,
      required: true
    }
  },
  computed: {
    statusIcon() {
      const statusIcons = {
        '예약완료': require('@/img/ic_reserved.svg'),
        '주차중': require('@/img/ic_parking.svg'),
        '출차완료': require('@/img/ic_checkedout.svg'),
        '예약취소': require('@/img/ic_cancelled.svg')
      };
      return statusIcons[this.reservation['예약상태']]|| require('@/img/logo.png');
    }
  },
  methods: {
    closeDetails() {
      this.$emit('close');
    },
    openPhoto() {
      const reservId = this.reservation['reservationId']
      this.$emit('photo', reservId);
    },
  }
};
</script>

<style>
.reservation-details {
  border-radius: 8px;
  border: 1px solid var(--admin-button-color);
  width: 100%;
  max-width: 450px;
  margin: auto;
  position: relative;
}

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
}

.detail-separator {
  border: 0;
  height: 1px;
  background: var(--admin-button-color);
}

.status {
  display: flex;
  align-items: center;
}

.status-icon {
  width: 25%; /* 원하는 너비로 설정 */
}

.reservation-info {
  display: flex;
  flex-direction: column;
}

.parking-lot {
  margin-left: 10px;
  font-weight: bold;
  font-size: 1rem;
}

.dates {
  margin-left: 10px;
  font-size: 0.8rem;
  color: gray;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.content {  
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.content h3 {
  font-size: 1rem;
}

.info-row {
  display: flex;
  position: relative;
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: space-between;
  margin-top: 10px;
}

.info-col {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.label {
  color: gray;
}

.value {
  margin: 5px 0px;
}

.v-line{
  border-left: 1px solid var(--admin-button-color);
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
 }

.button-row {
  display: flex;
  position: relative;
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center;
  margin-top: 10px;
}

.photo-button {
  width: 80%;
  background: var(--admin-main-color); 
  font-size: 1rem;
  border-radius: 6px;
  border: none;
  padding: .8rem;
  margin-top: 1rem;
  cursor: pointer;
  color: var(--admin-menu1-color);
}
</style>
