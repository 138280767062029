class ParkingArea {
  constructor({ id, name }) {
    this.id = id;
    this.name = name;
  }
}

class ParkingAreaList {
  constructor(list = []) {
    this.list = list.map(item => new ParkingArea(item));
  }

  get displayList() {
    return this.list.map(item => item.name).join(', ');
  }
}


export class managerDTO {
  constructor({ id, name, role, parkingAreaList }) {
    this.id = id;
    this.name = name;
    this.role = role;
    this.parkingAreaList =  new ParkingAreaList(parkingAreaList);
  }


  get displayData() {
    return {
      'id': this.id,
      "관리자 ID": this.id,
      "관리자 이름": this.name,
      "분류": this.role,
      "관리 주차장": this.parkingAreaList.displayList
    };
  }

}

