// Vuex parkingLot module
export default {
    namespaced: true,
    state: {
      selectedParkingLot: null,
    },
    mutations: {
      SET_SELECTED_PARKING_LOT(state, parkingLot) {
        state.selectedParkingLot = parkingLot;
      },
      CLEAR_PARKING_LOT(state) {
        state.selectedParkingLot = null;
      }
    },
    actions: {
      setselectParkingLot({ commit }, parkingLot) {
        console.log("Vuex Action - Setting Parking Lot:", parkingLot); 
        commit('SET_SELECTED_PARKING_LOT', parkingLot);
      },
      removeselectParkingLot({ commit }) {
        commit('CLEAR_PARKING_LOT');
      },
    },
    getters: {
      selectedParkingLot: (state) => state.selectedParkingLot,
    }
  };
  