<template>
  <div id="app">
    <template v-if="isLoginPage || isNotFoundPage">
      <router-view />
    </template>
    <template v-else>
    <AppHeader />
    <div class="container">
      <AppSidebar />
      <main>
        <router-view />
      </main>
    </div>
    </template>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppSidebar from './components/AppSidebar.vue';

export default {
  components: {
    AppHeader,
    AppSidebar
  },
  computed: {
    isLoginPage() {
      return this.$route.path === '/admin' || this.$route.path === '/'|| this.$route.path === '/privacy'|| this.$route.path === '/n_privacy'|| this.$route.path === '/location'
      || this.$route.path === '/pay' || this.$route.path === '/payrefund';
    },
    isNotFoundPage() {
      return this.$route.name === 'NotFound';
    }
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
    background-color: var(--admin-main-color);
}

.container {
  display: flex;
  flex: 1;
}

main {
  flex: 1;
  margin: 0px 20px 20px 0px;
  padding: 20px;
  border-radius: 20px;
  background-color: white; /* 본문 부분의 배경을 흰색으로 설정 */
  overflow-y: auto;
}
</style>
