<template>
  <div class="edit-fee-div">
    <div class="default-fee-container">
      <div class="title-c">기본 요금 설정값</div>
      <input
        type="text"
        v-model=defaultFee
        @input="validateFee"
        class="default-fee-input"
        :disabled="!isEditable"
        placeholder="기본요금" />
      <h2 class="admin-title no-margin">원</h2>

    <button class="submit-button" @click="toggleEdit">
      {{ isEditable ? '저장' : '수정' }}
    </button>
      
  </div>
  <div class="editable-table-container">
    <div style="margin-bottom:15px">
      <h2 class="admin-title">주차기간에 따른 할인/할증 설정</h2>
    </div>
    <div class="add-group">
      <input 
        type="text"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '')" 
        v-model="newsurcharge.startDay" 
        class="surcharge-input"
        placeholder="주차일(시작)" />
      <input 
        type="text"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '')" 
        v-model="newsurcharge.endDay" 
        class="surcharge-input"
        placeholder="주차일(종료)" />
      <input 
        type="text"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '')" 
        v-model="newsurcharge.surcharge" 
        class="surcharge-input"
        placeholder="할증요금" />
      <input 
        type="text"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '')" 
        v-model="newsurcharge.discount" 
        class="surcharge-input"
        placeholder="할인요금" />
      <button @click="addItem" class="submit-button">추가</button>
    </div>
      
    <div class="title-group"> 
      <p>주차일</p>
      <p>할증요금</p>
      <p>할인요금</p>
      <p style="width: 20px;"> </p>
    </div>
    <div v-if="surchargeList && surchargeList.length">
      <div class="surcharge-item" v-for="(element, index) in surchargeList" :key="element.id" >
        <div class="input-group">
          <div class="surcharge-text">{{ element.startDay }} ~ {{ element.endDay }}일</div>
          <div class="surcharge-text">{{ element.surcharge != null ? element.surcharge : 0 }} 원 </div>
          <div class="surcharge-text">{{ element.discount != null ? element.discount : 0 }} 원 </div>
          <div class="delete-cell">
            <img 
              src="@/img/ic_delete_button.svg" 
              alt="삭제" 
              class="delete-icon" 
              @click.stop="deleteItem(index)" />
          </div>
        </div>
      </div>
      </div>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
  </div>
</template>
  
<script>
  import { surchargeDTO } from '@/api/dto/parkingLotDTO';
  import { formatCurrency, parseCurrency } from '@/utils/dataFormatUtils';
  
  export default {
    props: {
      surchargeList: {
        type: Array,
        required: true,
      },
      originFee: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        errorMessage: '',
        newsurcharge: {
          startDay: null,
          endDay: null,
          surcharge: null,
          discount: null,
        },
        defaultFee: formatCurrency(this.originFee),
        isEditable: false // 입력 필드 수정 가능 여부를 제어하는 상태
      };
    },
    watch: {
      // originFee가 변경될 때 defaultFee도 업데이트
      originFee(newVal) {
        this.defaultFee = formatCurrency(newVal);
      }
    },
    methods: {
      addRow() {
        const newRow = new surchargeDTO({
          startDay: null,
          endDay: null,
          surcharge: null,
          discount: null,
        });
        this.$emit('update-surcharge-list', [...this.surchargeList, newRow]);
      },
      deleteItem(index) {
        const itemToDelete = this.surchargeList[index];
        this.surchargeList.slice(index, 1);
        this.$emit('delete-surcharge', itemToDelete.id);
      },
      addItem() {
        this.$emit('add-surcharge', this.newsurcharge);
      },
      validateFee() {
        this.defaultFee = this.defaultFee.replace(/[^0-9.]/g, '');
      },
      toggleEdit() {
        // 버튼 클릭 시 수정 가능 상태를 토글
        this.isEditable = !this.isEditable;
        if (this.isEditable) {
          console.log('수정시작', this.originFee, this.defaultFee)
        } else {
          this.$emit('update-default-fee', {'defaultCharge':parseCurrency(this.defaultFee)})
          console.log('저장완료', this.originFee, this.defaultFee)
        }
      }
    },
  };
</script>
  
<style scoped>
  .edit-fee-div {
    width: 100%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }

  .default-fee-container {
    background-color: var(--admin-main-color);
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .title-c {
    flex: 4;
  }

  .default-fee-container input {
    flex: 1;
    padding: .5rem;
    margin: 0 1rem;
    border: none;
    border-radius: .5rem;
    text-align: end;
    font-size: 1.1rem;
  }

  .default-fee-container input:disabled{
    flex: 1;
    padding: .5rem;
    margin: 0 1rem;
    border: none;
    border-radius: .5rem;
    text-align: end;
  }
  
  .editable-table-container {
    background-color: var(--admin-main-color);
    padding: 15px;
    border-radius: 10px;
  }
  
  .input-group {
    display: flex;
    background-color: white;
    border: 1px solid white;
    border-radius: 5px;
    align-items: center;
    margin: 5px 0px;
    padding: .5rem 0px;
    justify-content: space-around; 
    position: relative; /* 추가 */
  }

  .title-group {
    display: flex;
    border-bottom: 1px solid var(--admin-button-color);
    align-items: center;
    margin: 1rem 0px;
    padding: 0px;
    justify-content: space-around; 
    position: relative;
  }

  .add-group {
    display: flex;
    align-items: center;
    margin: 1rem 0px;
    padding: 0px;
    justify-content: start; 
    position: relative;
    flex-wrap: wrap;
  }

  .surcharge-input {
    width: 90px;
    padding: .5rem;
    margin-right: .5rem;
    border: none;
    border-radius: .5rem;
    font-size: .9rem;
  }
  
  .submit-button {
    min-width: 60px;
    padding: .5rem;
    margin: 0px 0px 0px .5rem ;
  }

  .title-group p, .surcharge-text{
    width: 70px;
    text-align: center;
    cursor: default;
  }

  .input-group:hover {
    border: 1px solid #636D97;
  }

  .delete-cell {
    width: 20px;
  }
  
  .delete-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  

  .error-message {
    color: red;
    margin-top: 10px;
  }

  ::selection {
    background: transparent;
  }
</style>
  