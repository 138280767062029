<template>
  <div class="table-pagination-container">
    <div class="table-container" style=" height: 100%;">
      <table style="width: 100%;">
        <thead>
          <tr>          
            <th v-for="column in columns" :key="column">{{ column }}</th>
            <th v-if="showDeleteColumn">삭제</th> <!-- 삭제 컬럼 조건부 렌더링 -->
          </tr>
        </thead>
        <tr 
          v-for="(data, index) in paginatedData" 
          :key="data.id"
          @click="handleRowClick(data)">
          <td v-for="column in columns" :key="column">
              {{ data[column] }}
              <!-- <button v-if="column === '관리 주차장'" @click="$emit('edit-item', index + (currentPage - 1) * itemsPerPage)" class="edit-button">
                <img class="edit-icon" src="@/img/icons/ic_edit.svg" alt="Edit" />      
              </button> -->
          </td>
          <td v-if="showDeleteColumn" class="delete-cell">
            <img 
              src="@/img/ic_delete_button.svg" 
              alt="삭제" 
              class="delete-icon" 
              @click.stop="deleteItem(index + (currentPage - 1) * itemsPerPage)" 
            /> <!-- 삭제 아이콘 추가 -->         
          </td> <!-- 삭제 버튼 조건부 렌더링 -->
        </tr>
      </table>
    </div>
    <TablePagination 
      :currentPage="currentPage" 
      :totalPages="totalPages" 
      @page-changed="handlePageChange" 
    />
  </div>

</template>

<script>
import TablePagination from '@/components/TablePagination.vue';

export default {
  components: {
    TablePagination,
  },

  props: {
    APIDatas: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    showDeleteColumn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 15
    };
  },
  computed: {
    paginatedData() {
      if (!this.APIDatas || this.APIDatas.length === 0) {
        return 1;
      }
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.APIDatas.slice(start, end);
    },
    totalPages() {
      if (!this.APIDatas || this.APIDatas.length === 0) {
        return 1;
      }
      return Math.ceil(this.APIDatas.length / this.itemsPerPage);
    }
  },
  methods: {
    handleRowClick(data) {
      this.$emit('row-click', data);
    },
    deleteItem(index) {
      this.$emit('delete-item', index);
    },
    handlePageChange(page) {
      this.currentPage = page;
    }
  }
};

</script>

<style scoped>
  .table-pagination-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .delete-cell {
    display: table-cell;
    vertical-align: middle;
    justify-content: center;
  }

  .edit-button {
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
  }

  .edit-icon {
    vertical-align: center;
    padding: auto;
    height: 20px;
  }

  .delete-icon {
    height: 20px;
  }
</style>
