<template>
  <div>
    <h2 class="admin-title">전체주차장 리스트</h2>
    <div class="content-container">
      <ManageTable 
        :APIDatas="APIDatas" 
        :columns="columns" 
        class="table-container"
        :showDeleteColumn=false />
    </div>
  </div>
</template>

  
<script>
import ManageTable from '@/components/ManageTable.vue';
import { getParkingLotsAll } from '@/api/api';

export default {
  components: {
    ManageTable,
  },

  data() {
    return {
      columns: ["주차장명","주차면 수", "주소", "연락처", "영업 시간"],
      APIDatas: [],
      formFields: [
        { name: 'parkinglot', label: '주차장 이름', type: 'text', placeholder: '' },
        { name: 'address1', label: '주차장 주소', type: 'address', placeholder: '시/도' },
        { name: 'parkingseats', label: '주차면수', type: 'text', placeholder: '숫자만 기입해주세요' },
        { name: 'contact', label: '연락처', type: 'text', placeholder: '000-0000-0000' },
        { name: 'opentime', label: '영업시작 시간', type: 'time', placeholder: '' },
        { name: 'closetime', label: '영업종료 시간', type: 'time', placeholder: '' }
      ]
    };
  },

  methods: {
    async fetchParkingLotsData() {
      try {
        const APIDatas = await getParkingLotsAll();
        this.APIDatas = APIDatas.map(data => data.displayData);

      } catch (error) {
        console.error('Error fetching parking lots:', error);
      }
    },

  },
  created() {
    this.fetchParkingLotsData();
  }

};
</script>