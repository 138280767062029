import { formatPhoneNumber } from "@/utils/dataFormatUtils";

export class airportDTO {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.sido = data.sido;
    this.sigungu = data.sigungu;
    this.dong = data.dong;
    this.detailAddress = data.detailAddress;
    this.lat = data.lat;
    this.lng = data.lng;
    this.contact = data.contact;
  }
  get displayData() {
    return {
      "No": this.id,
      "공항명": this.name,
      "시도": this.sido,
      "시군구": this.sigungu,
      "동": this.dong,
      "상세주소": this.detailAddress,
      "주소": `${this.sido} ${this.sigungu ||''} ${this.dong ||''} ${this.detailAddress||''}`,
      "위도": this.lat,
      "경도": this.lng,
      "연락처": formatPhoneNumber(this.contact),
    };
  }
}

export class stationDTO {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.sido = data.sido;
    this.sigungu = data.sigungu;
    this.dong = data.dong;
    this.detailAddress = data.detailAddress;
    this.lat = data.lat;
    this.lng = data.lng;
    this.type = data.type;
    this.radius = data.radius;
    this.airportId = data.airportId;
  }
  get displayData() {
    return {
      "No": this.id,
      "정류장": this.name,
      "시도": this.sido,
      "시군구": this.sigungu,
      "동": this.dong,
      "상세주소": this.detailAddress,
      "주소": `${this.sido} ${this.sigungu ||''} ${this.dong ||''} ${this.detailAddress||''}`,
      "위도": this.lat,
      "경도": this.lng,
      "타입": this.type,
      "반지름": this.radius,
      "airportId": this.airportId,
    };
  }

}