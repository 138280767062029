<template>
  <div>
    <h2 class="admin-title">회원 관리</h2>
    <div class="content-container">
      <ManageTable 
        :APIDatas="APIDatas" 
        :columns="columns" 
        class="table-container"
        :showDeleteColumn=false
        />
    </div>
  </div>
</template>

  
<script>
import ManageTable from '@/components/ManageTable.vue';
import { getCustomers } from '@/api/api';

export default {
  components: {
    ManageTable,
  },
  
  data() {
    return {
      columns: ["연락처", "제3자 제공 동의 여부", "보유차량"],
      APIDatas: [],
    };
  },

  methods: {
    async fetchDriversData() {
      try {
        const APIDatas = await getCustomers();
        this.APIDatas = APIDatas.map(data => data.displayData);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    },
  },

  created() {
    this.fetchDriversData();
  }
};
</script>