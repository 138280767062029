import { createRouter, createWebHistory } from 'vue-router'
import ParkingReservation from '@/pages/ParkingReservation.vue'
import BusReservation from '@/pages/BusReservation.vue'
import CarEntryExit from '@/pages/CarEntryExit.vue'
import ManageFee from '@/pages/ManageFee.vue'
import ManageDriver from '@/pages/ManageDriver.vue'
import ManageCustomer from '@/pages/ManageCustomer.vue'
import ManageParkinglot from '@/pages/ManageParkinglot.vue'
import ManageBanReserv from '@/pages/ManageBanReserv.vue'
import AdminLogin from '@/pages/AdminLogin.vue'
import PrivatePage from '@/pages/PrivatePage.vue'
import LocationPage from '@/pages/LocationPage.vue'
import OtherPage from '@/pages/OtherPage.vue'
import NotFoundPage from '@/pages/NotFoundPage.vue'
import SalesStatistics from '@/pages/SalesStatistics.vue'
import ManageAuth from '@/pages/ManageAuth.vue'
import PaypleWebview from '@/pages/PaypleWebview.vue'
import PayplecancelWebview from '@/pages/PayplecancelWebview.vue'
import ParkinglotList from '@/pages/ParkinglotList.vue'
import InquiryPage from '@/pages/InquiryPage.vue'
import store from '../../store'
import ManageAirport from '@/pages/ManageAirport.vue'
import ManageStation from '@/pages/ManageStation.vue'

const routes = [
  { path: '/admin', name: 'admin', component: AdminLogin },
  { path: '/admin/sales-statistics', name: 'sales', component: SalesStatistics },
  { path: '/admin/parking-reservation', name: 'parkingReservation', component: ParkingReservation },
  { path: '/admin/bus-reservation', name: 'busReservation', component: BusReservation },
  { path: '/admin/car-entry-exit', name: 'carEntryExit', component: CarEntryExit },
  { path: '/admin/settings-fee', name: 'ManageFee', component: ManageFee },
  { path: '/admin/settings-ban', name: 'ManageBanReserv', component: ManageBanReserv },
  { path: '/admin/settings-driver', name: 'ManageDriver', component: ManageDriver },
  { path: '/admin/settings-member', name: 'ManageCustomer', component: ManageCustomer },
  { path: '/admin/settings-parkinglot', name: 'ManageParkinglot', component: ManageParkinglot },
  { path: '/admin/settings-auth', name: 'ManageAuth', component:ManageAuth},
  { path: '/admin/parkinglots', name: 'AllParkinglots', component:ParkinglotList},
  { path: '/admin/airports', name: 'ManageAirport', component:ManageAirport},
  { path: '/admin/stations', name: 'ManageStation', component:ManageStation},
  { path: '/admin/inquiries', name: 'ManageInquiries', component:InquiryPage},


  
  { path: '/location', name: 'PrivatePage', component: PrivatePage },
  { path: '/privacy', name: 'LocationPage', component: LocationPage },
  { path: '/n_privacy', name: 'OtherPage', component: OtherPage },
  { path: '/:catchAll(.*)', name: 'NotFound', component: NotFoundPage },
  { path: '/pay', name: 'PaypleWebview', component: PaypleWebview},
  { path: '/payrefund', name: 'PayplecancelWebview', component: PayplecancelWebview}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // url끝의 슬래시 제거
  if (to.path !== '/' && to.path.endsWith('/')) {
    return next(to.path.slice(0, -1));
  } 

  // 로그인 검사
  const adminPagesPrefix = '/admin/';
  const authRequired = to.path.startsWith(adminPagesPrefix);
  const loggedIn = store.state.userInfo.role;

  if (authRequired && !loggedIn) {
      return next('/admin');
  }

  next();
});

export default router
