<template>
  <div class="admin-page">
    <h2 class="admin-title">문의 내역</h2>
    <div v-if=selectedReservation>
      <ToastificationContent
        :inquiryData="selectedReservation"
        @close = "closeModal"/>
    </div>
    <div v-else class="admin-page">
      <div class="filter-search-container">
        <FilterButton v-model="activeFilter" :filters="filters" />
      </div>
      <div class="content-container no-margin-padding">
        <div ref="tableContainer" :class="['table-container', { 'table-selected': selectedReservation }]">
          <ReservationTable
            :reservations="filteredInquiries"
            :columns="columns"
            @row-click="selectReservation"
            :selectedReservation="selectedReservation"/>
        </div>
      </div>
    </div>
  </div>      
</template>


<script>
  import FilterButton from '@/components/FilterButton.vue';
  import ReservationTable from '@/components/ReservationTable.vue';
  import { apiClient } from '@/api/api.js'
  import ToastificationContent from '@/components/ToastificationContent.vue';
import { inquiriesDTO } from '@/api/dto/inquiriesDTO';

  export default {
    components: {
      ReservationTable,
      FilterButton,
      ToastificationContent
    },
    data() {
      return {
        loading: true,
        columns: ["분류", "작성자","작성날짜","답변여부"],     
        selectedReservation: null,
        inquiries: [],
        activeFilter: 'all',
        filters: [
          { label: '전체', value: 'all' },
          { label: '미답변 보기', value: 'WAITING' },
        ],
        showModal: false,
      };
    },
    created() {
      this.fetchInquiries();
    },
    computed: {
      filteredInquiries() {
        let inquiries = this.inquiries;
        if (this.activeFilter !== 'all') {
          inquiries = inquiries.filter(
            (inquiry) => inquiry.status === this.activeFilter
          );
        }

        return inquiries.sort((a,b) => b.id-a.id).map((inquiry) => inquiry.displayData);
      },
    },
    watch: {
      activeFilter() {
        this.selectedReservation = null; 
      }
    },
    methods: {
      async fetchInquiries() {
        try {
          const inquiries = await apiClient.get('/inquiries');
          this.inquiries = inquiries.data.list.map(inquiry => new inquiriesDTO(inquiry));
          console.log(this.inquiries)
        } catch (error) {
          console.error(error);
        } 
      },
      selectReservation(reservation) {
        this.selectedReservation = reservation;
      },
      clearReservation() {
        this.selectedReservation = null;
      },
      handleClickOutside(event) {
        const reservationDetail = this.$refs.reservationDetail;
        const tableContainer = this.$refs.tableContainer;
        if (reservationDetail && !reservationDetail.$el.contains(event.target) &&
        tableContainer && !tableContainer.contains(event.target)) {
          this.clearReservation();
        }
      },
      closeModal() {
        this.fetchInquiries()
        this.selectedReservation = null
      }
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleClickOutside);
    }
  };
</script>
  
<style>
  .filter-search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .table-container {
    flex: 2; /* 테이블이 더 큰 너비를 차지하도록 설정 */
    transition: flex 0.3s; /* 부드러운 전환 효과 추가 */
  }

</style>