<!-- src/components/EmptyState.vue -->
<template>
  <div class="empty-state">
    <img src="@/img/icons/ic_empty.svg" alt="Empty Icon" class="empty-icon" />
    <p class="primary-message">{{ primaryMessage }}</p>
    <p class="secondary-message no-margin">{{ secondaryMessage }}</p>
  </div>
</template>

<script>
export default {
  props: {
    primaryMessage: {
      type: String,
      default: '관리 가능한 주차장이 없습니다.'
    },
    secondaryMessage: {
      type: String,
      default: '주차장 관리 탭에서 주차장을 추가해주세요.'
    }
  }
};
</script>

<style scoped>
.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-weight: bold;
}

.empty-icon {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.primary-message {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.secondary-message {
  font-size: 1em;
}
</style>
