<template>
  <div id="cancel-payment-page">
    <h1>결제 취소</h1>
    <div v-if="isLoading">결제 취소 정보를 불러오는 중...</div>
    <div v-else-if="error" class="error">
      <h2>오류 발생</h2>
      <p>{{ error }}</p>
    </div>
    <div v-else-if="cancellationInfo" class="cancellation-info">
      <h2>결제 취소 정보</h2>
      <p>예약 번호: {{ cancellationInfo.reservation_id }}</p>
      <p>결제 금액: {{ cancellationInfo.amount }}원</p>
      <p>결제 일시: {{ formatDate(cancellationInfo.payment_date) }}</p>
      <button @click="requestCancel">결제 취소 확인</button>
    </div>
    <div v-else>
      <p>취소할 수 있는 결제 정보가 없습니다.</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getCancellationInfo } from '@/api/api';

export default {
  setup() {
    const isLoading = ref(true);
    const error = ref(null);
    const cancellationInfo = ref(null);

    const loadCancellationInfo = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const reservationId = urlParams.get('reservation_id');

        if (!reservationId) {
          throw new Error('예약 ID가 제공되지 않았습니다.');
        }

        const response = await getCancellationInfo(reservationId);
        cancellationInfo.value = response;
      } catch (err) {
        console.error('결제 취소 정보 로딩 중 오류 발생:', err);
        error.value = '결제 취소 정보를 불러오는 중 오류가 발생했습니다.';
      } finally {
        isLoading.value = false;
      }
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString();
    };

    const requestCancel = () => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          type: 'CONFIRM_PAYMENT_CANCELLATION',
          data: cancellationInfo.value
        }));
      } else {
        console.log('결제 취소 확인:', cancellationInfo.value);
      }
    };

    onMounted(() => {
      loadCancellationInfo();
    });

    return {
      isLoading,
      error,
      cancellationInfo,
      formatDate,
      requestCancel
    };
  }
};
</script>

<style scoped>
#cancel-payment-page {
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}
.error {
  color: red;
  margin-top: 20px;
}
.cancellation-info {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
}
button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #ff4444;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>