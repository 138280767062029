<template>
  <div>
    <h2 class="admin-title">주차장 관리</h2>
    <div class="content-container">
      <ManageTable 
        v-if="APIDatas.length > 0"
        :APIDatas="APIDatas" 
        :columns="columns" 
        class="table-container"
        @row-click="modifyData"
        @delete-item="deleteItem"
        :showDeleteColumn=true
        style="width: 80%;" />
      <FormComponent
        :fields="formFields"
        submitButtonText="주차장 추가"
        @submit="handleFormSubmit"
        class="form-container"
        style="width: 20%;"
      />
    </div>
    <ModifyModal
      v-if="showModify"
      :parkinglot = "selectedData"
      :isVisible="showModify"
      @close="closeModify"
    />
  </div>
</template>

  
<script>
import ManageTable from '@/components/ManageTable.vue';
import FormComponent from '@/components/FormComponent.vue';
import { getParkingLots, postParkingLots, getGeocoding, deleteParkingLots,getAirPort } from '@/api/api';
import ModifyModal from '@/components/ModifyModal.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ManageTable,
    FormComponent,
    ModifyModal
  },

  data() {
    return {
      columns: ["주차장명","주차면 수", "주소", "연락처", "영업 시간", "최소금액", "버스금액"],
      APIDatas: [],
      formFields: [
        { name: 'airportId', label: '근처공항', type: 'select', placeholder: '공항을 선택해주세요', selectList: []},
        { name: 'parkinglot', label: '주차장 이름', type: 'text', placeholder: '' },
        { name: 'address1', label: '주차장 주소', type: 'address', placeholder: '시/도' },
        { name: 'defaultFee', label: '주차장 기본요금', type: 'text', placeholder: '숫자만 기입해주세요' },
        { name: 'parkingseats', label: '주차면수', type: 'text', placeholder: '숫자만 기입해주세요' },
        { name: 'contact', label: '연락처', type: 'text', placeholder: '000-0000-0000' },
        { name: 'opentime', label: '영업시작 시간', type: 'time', placeholder: '' },
        { name: 'closetime', label: '영업종료 시간', type: 'time', placeholder: '' },
        { name: 'callPay', label: '버스금액', type: 'text', placeholder: '숫자만 기입해주세요' },
        { name: 'minPay', label: '최소금액', type: 'text', placeholder: '숫자만 기입해주세요' },
      ],
      
      showModify: false,
      selectedData: null
    };
  },

  methods: {
    ...mapActions('airport', ['setairportlist']),
    async fetchAirport() {
      const data = await getAirPort();
      await this.setairportlist(data); 
    },
    async fetchParkingLotsData() {
      try {
        const APIDatas = await getParkingLots();
        this.APIDatas = APIDatas.map(data => data.displayData);

      } catch (error) {
        console.error('Error fetching parking lots:', error);
      }
    },
    async handleFormSubmit(formData) {
      if (formData instanceof SubmitEvent) {
        console.warn('Invalid form data:', formData);
        return;
      }
      const fulladdress = `${formData.sidoname} ${formData.sigunguname} ${formData.eupmyundongname} ${formData.detailname}`;

      try {
        const response = await getGeocoding(fulladdress);
        if (response.errCd === -100) {
          alert('주차장 지번주소를 확인할 수 없습니다. 다시 확인해주세요.');
          return;
        }
        const geocodingResults = response.result.resultdata.map(feature => ({
          lat: feature.y,
          lng: feature.x
        }))

        const { lng, lat } = geocodingResults[0];
        const dataToSend = {
          name: formData.parkinglot,
          sido: formData.sidoname,
          sigungu: formData.sigunguname,
          dong: formData.eupmyundongname,
          detailAddress: formData.detailname,
          lat: parseFloat(lat),
          lng: parseFloat(lng),
          seats: parseInt(formData.parkingseats),
          contact: formData.contact,
          openTime: formData['opentime_string'],
          closeTime: formData['closetime_string'],
          airportId: formData.airportId,
          defaultCharge: parseInt(formData.defaultFee),
          callPay: parseInt(formData.callPay),
          minPay: parseInt(formData.minPay),
        };

        await postParkingLots(dataToSend);
        this.fetchParkingLotsData();
        alert(`${dataToSend.name} 주차장이 성공적으로 등록되었습니다.`);

      } catch (error) {
        console.error('Error submitting form:', error);
        alert('주차장 등록 중 오류가 발생했습니다. 다시 시도해주세요.');
      }
    },
    modifyData(data) {
      this.showModify = true;
      this.selectedData = data
    },
    closeModify() {
      this.showModify = false;
      this.fetchParkingLotsData();
    },
    async deleteItem(index) {
      const parkinglotID = this.APIDatas[index].No;
      const parkinglotName = this.APIDatas[index].주차장명;

      // 확인 팝업 띄우기
      const confirmed = confirm(`${parkinglotName} 주차장을 삭제하시겠습니까?`);

      if (confirmed) {
        try {
          await deleteParkingLots(parkinglotID);
          this.APIDatas.splice(index, 1);
        } catch (error) {
          console.error('Error deleting reservation:', error);
          alert('주차장 삭제 중 오류가 발생했습니다. 다시 시도해주세요.');

        }
      } else {
        console.log('cancelled');
      }
    },

  },
  computed: {
    ...mapGetters('airport', ['getIdNamePairs']),
  },
  created() {
    this.fetchParkingLotsData()
    this.fetchAirport();
  },
  mounted() {
    // 컴포넌트가 마운트될 때 selectList를 업데이트
    this.formFields.find(field => field.name === 'airportId').selectList = this.getIdNamePairs;
  }

};
</script>