<template>
    <LocationPolicy />
  </template>
  
<script>
  import LocationPolicy from '@/components/LocationPolicy.vue'
  
  export default {
    components: {
      LocationPolicy
    }
  }
</script>
  