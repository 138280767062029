export class parkingBanDTO {
  constructor({
    id,
    startDateTime,
    endDateTime
  }) {
    this.id = id;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
  }

  get displayData() {
    return {
      "No": this.id,
      "시작일": this.formatTime(this.startDateTime),
      "종료일": this.formatTime(this.endDateTime)
    };
  }

  formatTime(date) {
    const d = new Date(date);
    const optionsDate = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'short'
    };
    const optionsTime = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    };
    
    const formattedDate = new Intl.DateTimeFormat('ko-KR', optionsDate).format(d);
    const formattedTime = new Intl.DateTimeFormat('ko-KR', optionsTime).format(d);

    const [year,Month,Day, weekday] = formattedDate.split(' ');
    const formattedDateWithWeekday = `${year} ${Month} ${Day} (${weekday})`;

    return `${formattedDateWithWeekday} ${formattedTime}`;
  }


}