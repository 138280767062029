<template>
    <div v-if="isVisible" class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
            <button @click="closeModal" class="close-button">X</button>
            <h2 class="modify-title"> 정류장 정보 수정</h2>
            <div class="info-row">
            <span class="label">정류장</span>
            <input type="text" v-model="localData['정류장']" id="name" />
          </div>
          <div class="info-row">
            <span class="label">시도</span>
            <input type="text" v-model="localData['시도']" id="sido" />
          </div>
          <div class="info-row">
            <span class="label">시군구</span>
            <input type="text" v-model="localData['시군구']" id="sigungu" />
          </div>
          <div class="info-row">
            <span class="label">동</span>
            <input type="text" v-model="localData['동']" id="dong" />
          </div>
          <div class="info-row">
            <span class="label">상세주소</span>
            <input type="text" v-model="localData['상세주소']" id="detailAddress" />
          </div>
          <div class="info-row">
            <span class="label">위도</span>
            <input type="text" v-model="localData['위도']" id="lat" />
          </div>
          <div class="info-row">
            <span class="label">경도</span>
            <input type="text" v-model="localData['경도']" id="lng" />
          </div>
          <div class="info-row">
            <span class="label">타입</span>
            <input type="text" v-model="localData['타입']" id="type" />
          </div>
          <div class="info-row">
            <span class="label">반지름</span>
            <input type="number" v-model="localData['반지름']" id="radius" />
          </div>
          <div class="info-row">
            <span class="label">근처공항</span>
            <select v-model="localData['airportId']" id="airportId">
              <option v-for="option in airportList" 
              :key="option.id" 
              :value="option.id" 
              class="form-select-option">{{ option.name || option }}</option>
            </select>
          </div>
            <div class="button-group">
              <button type="submit" class="submit-button" @click="handelSubmit">수정내용 저장</button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { modifyStation } from '@/api/api';
  export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        airportList: {
            type: Array,
            required: true
        },
        isVisible: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
          localData: { ...this.data }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        async handelSubmit() {
            console.log(this.localData)
            try {
                const dataToSend = {
                    name: this.localData.정류장,
                    sido: this.localData.시도,
                    sigungu: this.localData.시군구,
                    dong: this.localData.동,
                    detailAddress: this.localData.상세주소,
                    lat: parseFloat(this.localData.위도),
                    lng: parseFloat(this.localData.경도),
                    type: this.localData.타입,
                    radius: parseInt( this.localData.반지름),
                    airportId: parseInt(this.localData.airportId),
                };

                await modifyStation(this.localData.No, dataToSend);
                alert(`${dataToSend.name} 정보가 수정되었습니다.`);
                this.$emit('close');

            } catch (error) {
                console.error('Error submitting form:', error);
                alert('정보수정 중 오류가 발생했습니다. 다시 시도해주세요.');
            }
        }
    }   
};
</script>
  
<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
    
  .modal-content {
    width: 40%;
    min-height: 30%;
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .modify-title {
    text-align: center;
  }
.close-button {
  position: absolute;
  background-color: transparent;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;
}

.info-row {
  display: flex;
  position: relative;
  align-items: center; /* 수직 중앙 정렬 */
  margin: 10px 0;
  justify-content: space-between;
}

.label {
  width: 20%;
  color: gray;
  font-size: 1.2rem;
  text-align: center;
}

.info-row input {
  width: 70%;
  font-size: 1rem;
  padding: 8px;
  border: 1px solid var(--admin-button-color);
  border-radius: 5px;
  box-sizing: border-box;
}

.info-row select {
  width: 70%;
  font-size: 1rem;
  padding: 8px;
  border: 1px solid var(--admin-button-color);
  border-radius: 5px;
  box-sizing: border-box;
}

</style>  
  