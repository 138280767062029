export default {
    namespaced: true,
    state: {
      airportList: null,
      idNamePairs: [] // id와 name을 짝지은 배열
    },
    mutations: {
      SET_AIRPORT_LIST(state, airport) {
        state.airportList = airport;
        state.idNamePairs = airport.map(item => ({
          id: item.id,
          name: item.name
        }));
      },
      SET_SELECTED_AIRPORT(state, airport) {
        state.selectedAirport = airport;
      }
    },
    actions: {
      setairportlist({ commit }, airport) {
        commit('SET_AIRPORT_LIST', airport);
      },
      setselectedairport({ commit }, airport) {
        commit('SET_SELECTED_AIRPORT', airport);
      }
    },
    getters: {
      airportList: (state) => state.airportList,
      getIdNamePairs: state => state.idNamePairs, // 'id'와 'name' 배열을 반환하는 getter
    }
  };
  