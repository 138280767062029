export const errorMessages = {
  //500 Internal Server Error
  'internal server error': '일시적인 오류로 서비스 접속에 실패했습니다.\n잠시후 다시 시도해주세요.',

  //400 BadRequest
  'this password is not correct': '비밀번호가 일치하지 않습니다.',


  //403 Forbidden

  //404 NotFound
  'this manager is not found': '등록되지 않은 아이디입니다.',

};


export const getErrorMessage = (error) => {
  const message = error.response?.data?.message || 'Unknown error';
  return errorMessages[message] || '알 수 없는 오류가 발생했습니다';
};