<template>
    <div class="terms">
        <h2>개인정보의 제3자 제공에 관한 사항</h2>
        <ol>
            <li>[ 주식회사 잇다모빌리티 ]는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</li>
            <li>[ 주식회사 잇다모빌리티 ]는 다음과 같이 개인정보를 제3자에게 제공할 수 있습니다.</li>
            <p>[ 이용자의 회사 ] <br/>
        개인정보를 제공받는 자 : 이용자가 결제 및 이용하는 시설<br/>
        제공받는 자의 개인정보 이용목적 : 이용자가 이용하는 시설의 예약자 확인 및 결제정보 확인, 탑승권한이 있는 탑승객의 확인 및 노선별 탑승객 정보를 확인하기 위함<br/>
        제공받는 자의 보유.이용기간: 1년
        </p>
        </ol>
    </div>
</template>
<script>
export default { name: 'OtherPolicy'}
</script>

<style scoped>
.nested {list-style-type: none; padding-left: 0;}
.terms {padding: 20px 40px; background-color: white; font-size: 14px; height: 100vh;}
ol {padding-left: 10px;}
</style>