<template>
    <div>
      <h2 class="admin-title">공항 관리</h2>
      <div class="content-container">
        <ManageTable 
          v-if="APIDatas.length > 0"
          :APIDatas="APIDatas" 
          :columns="columns" 
          class="table-container"
          @row-click="modifyData"
          @delete-item="deleteItem"
          :showDeleteColumn="showDeleteColumn"
          style="width: 80%;" />
        <FormComponent
          :fields="formFields"
          submitButtonText="공항 등록"
          @submit="handleFormSubmit"
          class="form-container"
          style="width: 20%;"
        />
      </div>
      <ModifyAirPort
        v-if="showModify"
        :data = "selectedData"
        :isVisible="showModify"
        @close="closeModify"
      />
    </div>
  </template>
  
    
  <script>
  import ManageTable from '@/components/ManageTable.vue';
  import FormComponent from '@/components/FormComponent.vue';
  import { postAirport, deleteAirport, getAirPort } from '@/api/api';
  import ModifyAirPort from '@/components/ModifyAirPort.vue';
  
  export default {
    components: {
      ManageTable,
      FormComponent,
      ModifyAirPort
    },
  
    data() {
      return {
        columns: ["공항명", "주소", "위도", "경도", "연락처"],
        APIDatas: [],
        formFields: [
          { name: 'name', label: '공항명', type: 'text', placeholder: '' },
          { name: 'address1', label: '주차장 주소', type: 'address', placeholder: '시/도' },
          { name: 'lat', label: '위도', type: 'text', placeholder: '' },
          { name: 'lng', label: '경도', type: 'text', placeholder: '' },
          { name: 'contact', label: '연락처', type: 'text', placeholder: '숫자만 기입해주세요' },
        ],
        showModify: false,
        selectedData: null
      };
    },
  
    methods: {
      async fetchAirportData() {
        try {
          const APIDatas = await getAirPort();
          this.APIDatas = APIDatas.map(data => data.displayData);  
        } catch (error) {
          console.error('Error fetching parking lots:', error);
        }
      },
      async handleFormSubmit(formData) {
        if (formData instanceof SubmitEvent) {
          console.warn('Invalid form data:', formData);
          return;
        }
  
        try {
  
          const dataToSend = {
            name: formData.name,
            sido: formData.sidoname,
            sigungu: formData.sigunguname,
            dong: formData.eupmyundongname,
            detailAddress: formData.detailname,
            lat: parseFloat(formData.lat),
            lng: parseFloat(formData.lng),
            contact: formData.contact,
          };
  
          await postAirport(dataToSend);
          this.fetchAirportData();
          alert(`${dataToSend.name}이 성공적으로 등록되었습니다.`);
  
        } catch (error) {
          console.error('Error submitting form:', error);
          alert('등록 중 오류가 발생했습니다. 다시 시도해주세요.');
        }
      },
      async deleteItem(index) {
        const parkinglotID = this.APIDatas[index].No;
        const parkinglotName = this.APIDatas[index].공항명;
  
        // 확인 팝업 띄우기
        const confirmed = confirm(`${parkinglotName}을 삭제하시겠습니까?`);
  
        if (confirmed) {
          try {
            await deleteAirport(parkinglotID);
            this.APIDatas.splice(index, 1);
          } catch (error) {
            console.error('Error deleting reservation:', error);
            alert('삭제 중 오류가 발생했습니다. 다시 시도해주세요.');
  
          }
        } else {
          console.log('cancelled');
        }
      },
      modifyData(data) {
        this.showModify = true;
        this.selectedData = data
      },
      closeModify() {
        this.showModify = false;
        this.fetchAirportData();
      },
    },
    created() {
      this.fetchAirportData();
    }
  
  };
  </script>