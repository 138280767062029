import { formatDate, formatTime, EngToKorean, formatCurrency, formatPhoneNumber } from '@/utils/dataFormatUtils';

export class parkingReservationDTO {
  constructor({
    id,
    customerPhone,
    customerType,
    vehicleType,
    vehicleNumber,
    reservationDate,
    startTime,
    endTime,
    price,
    status,
    requestFrom,
    flightType,
    isKeyStored,
    carrierCount,
    golfClubCount,
    peopleCount,
    parkingCall,
    airportCall
  }) {
    this.id = id;
    this.customerPhone = customerPhone;
    this.customerPhone4 = customerPhone.slice(-4);
    this.customerType = customerType;
    this.vehicleType = vehicleType;
    this.vehicleNumber = vehicleNumber;
    this.reservationDate = reservationDate;
    this.startTime = startTime;
    this.endTime = endTime;
    this.price = price;
    this.status = status;
    this.requestFrom = requestFrom;
    this.flightType = flightType;
    this.isKeyStored = isKeyStored;
    this.carrierCount = carrierCount;
    this.golfClubCount = golfClubCount;
    this.peopleCount = peopleCount;
    this.parkingCall = parkingCall;
    this.airportCall = airportCall;
  }

  get displayData() {
    return {
      "reservationId": this.id,
      "예약 인원": `${this.peopleCount} 명`,
      "연락처": formatPhoneNumber(this.customerPhone),
      "회원구분":this.customerType,
      "차량 정보": `${this.vehicleType} ${this.vehicleNumber}`,
      "예약일": formatTime(this.reservationDate),
      "이용일": `${formatDate(this.startTime)} ~ ${formatDate(this.endTime)}`,
      "입차일": formatDate(this.startTime),
      "출차일": formatDate(this.endTime),
      "총 금액": `${formatCurrency(this.price)} 원`,
      "예약상태": EngToKorean(this.status),
      "예약 플랫폼": EngToKorean(this.requestFrom),
      "항공타입": EngToKorean(this.flightType),
      "캐리어 수량": `${this.carrierCount} 개`,
      "골프백 수량": `${this.golfClubCount} 개`,
      "입차 시간": formatTime(this.startTime),
      "출차 시간": formatTime(this.endTime),
      "입출차 상태": EngToKorean(this.status),
    };
  }


  checkTime(startT, endT) {
    const now = new Date();
    const start = new Date(startT);
    const end = new Date(endT);

    if (now < start) {
      return '예약완료';
    } else if (now >= start && now <= end) {
      return '주차중';
    } else {
      return '출차완료';
    }
  }
}


export class busReservationDTO {
  constructor({
    parkingReservationId,
    customerPhone,
    customerType,
    flightType,
    golfClubCount,
    carrierCount,
    peopleCount,
    calledPlace,
    callStatus
  }) {
    this.parkingReservationId = parkingReservationId;
    this.customerType = customerType;
    this.customerPhone = customerPhone;
    this.customerPhone4 = customerPhone.slice(-4);
    this.flightType = flightType;
    this.golfClubCount = golfClubCount;
    this.carrierCount = carrierCount;
    this.peopleCount = peopleCount;
    this.calledPlace = calledPlace;
    this.callStatus = callStatus;
    this.getCall = this.getCallPlace(this.calledPlace, this.flightType);
    this.getArrive = this.getArrivePlace(this.calledPlace, this.flightType);
  }

  get displayData() {
    return {
      "예약ID": this.parkingReservationId,
      "회원구분":this.customerType,
      "예약 인원": `${this.peopleCount} 명`,
      "연락처": formatPhoneNumber(this.customerPhone),
      "항공타입": EngToKorean(this.flightType),
      "골프백 개수": `${this.golfClubCount} 개`,
      "캐리어 개수": `${this.carrierCount} 개`,
      "승차 정류장": this.getCallPlace(this.calledPlace, this.flightType),
      "하차 정류장": this.getArrivePlace(this.calledPlace, this.flightType),
      "상태": this.checkStatus(this.callStatus),
    };
  }

  getCallPlace(called) {
    return called === "PARKING" ? "주차장" : `입국 정류장`;
  }
  getArrivePlace(called, flight) {
    return called === "PARKING" ? `공항 ${EngToKorean(flight)}` : "주차장";
  }
  
  checkStatus(org) {
    const EngMapping = {
      "RESERVED": "예약",
      "CALLED": "호출",
      "ACCEPTED": "배차완료",
      "RIDDEN": "셔틀탑승",
    };
    return EngMapping[org] || org; // 매핑이 없는 경우 원래 값을 반환
  }
}