<template>
  <div class="modal">
    <div class="modal-content">
      <span class="close" @click="$emit('close')">&times;</span>
      <h2>주차장 권한 수정</h2>
      <div class="parking-lots">
        <h3>전체 주차장 리스트</h3>
        <ul>
          <li v-for="parkingLot in parkingLots" :key="parkingLot.id">
            {{ parkingLot.name }}
            <button @click="addParkingLot(parkingLot.id)">추가</button>
          </li>
        </ul>
        <h3>현재 관리 주차장 리스트</h3>
        <ul>
          <li v-for="parkingLot in localFormData.parkingLots" :key="parkingLot.id">
            {{ parkingLot.name }}
            <button @click="removeParkingLot(parkingLot.id)">제거</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fields: Array,
    formData: Object,
    parkingLots: Array,
    submitButtonText: String
  },
  data() {
    return {
      localFormData: { ...this.formData } // 로컬 상태로 formData 복사
    };
  },
  methods: {
    submitForm() {
      this.$emit('submit', this.localFormData);
    },
    addParkingLot(parkingLotId) {
      this.$emit('add-parking-lot', this.localFormData.managerId, parkingLotId);
    },
    removeParkingLot(parkingLotId) {
      this.$emit('remove-parking-lot', this.localFormData.managerId, parkingLotId);
    }
  },
  watch: {
    formData: {
      immediate: true,
      handler(newVal) {
        this.localFormData = { ...newVal }; // formData 변경 시 로컬 상태도 업데이트
      }
    }
  }
};
</script>

<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 50%;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.parking-lots {
  margin-top: 20px;
}
.parking-lots h3 {
  margin-bottom: 10px;
}
.parking-lots ul {
  list-style-type: none;
  padding: 0;
}
.parking-lots li {
  display: flex;
  justify-content: space-between
}
</style>