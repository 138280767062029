<template>
    <div v-if="isVisible" class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
            <button @click="closeModal" class="close-button">
                <img src="@/img/icons/ic_close.svg" />
            </button>

            <button @click="prevImage" :disabled="imageIndex === 1" class="page-button">
                <img src="@/img/icons/ic_prev_photo.svg" />
            </button>

            <img v-if="imageUrl" :src="imageUrl" alt="Reservation Photo" class="modal-image" />

            <button @click="nextImage" :disabled="imageIndex === 4" class="page-button">
                <img src="@/img/icons/ic_next_photo.svg" />
            </button>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
  export default {
    props: {
        reservId: {
            type: Number,
            required: true
        },
        isVisible: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            imageIndex: 1, 
            imageUrl: null
        };
    },
    created() {
        this.loadImage();
    },
    methods: {
        async fetchImage(id, imageIndex) {
            try {
                const response = await axios.get(`https://www.stationhere.co.kr/api/images?reservationId=${id}&direction=${imageIndex}`, {
                    responseType: 'blob' // 이미지 바이너리 데이터를 받기 위한 설정
                });
                const imageUrl = URL.createObjectURL(response.data);
                return imageUrl;
            } catch (error) {
                console.error("Error fetching image:", error);
                return null; // 에러 처리
            }
        },
        closeModal() {
            this.$emit('close');
        },
        async loadImage() {
            this.imageUrl = await this.fetchImage(this.reservId, this.imageIndex); // API 호출
        },
        async prevImage() {
            if (this.imageIndex > 1) {
                this.imageIndex--;
                this.imageUrl = await this.fetchImage(this.reservId, this.imageIndex);
            }
        },
        async nextImage() {
            if (this.imageIndex < 4) { // 이미지가 4개라고 가정
                this.imageIndex++;
                this.imageUrl = await this.fetchImage(this.reservId, this.imageIndex);
            }
        },
    }   
};
</script>
  
<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
    
  .modal-content {
    max-width: 80%;
    background-color: transparent;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
  }
  
  .page-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0 1rem;
  }

  .page-button img {
    width: 70px;
    height: 70px;
  }

  .page-button:disabled {
    cursor:default;
    opacity: 0;
  }

  .modal-image {
    max-width: 100%;
    max-height: 80vh;
  }

.close-button {
  position: absolute;
  top: -20px; /* 이미지 상단 위에 버튼 배치 */
  right: 120px; /* 이미지 오른쪽 끝에 버튼 배치 */
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.close-button img {
  width: 24px;
  height: 24px;
}
</style>  
  